const Modal = {
  display: function (template, onComplete, data) {
    const $ = jQuery;
    const $cbox = $("#cboxLoadedContent");
    const modal_opened = $cbox.is(":visible");
    var template_html = $("." + template).html();

    $.each(data, function (ind, datum) {
      template_html = template_html.replace("{" + ind + "}", datum);
    });

    if (modal_opened) {
      $cbox.html(template_html);
      $.colorbox.resize();
      onComplete();
    } else {
      $.colorbox({
        html: template_html,
        onComplete: onComplete,
        reposition: true,
      });
    }
  },
};

var exportAction = function (e, dt, button, config) {
  const self = this;
  const oldStart = dt.settings()[0]._iDisplayStart;

  dt.one("preXhr", function (e, s, data) {
    // Just this once, load all data from the server...
    data.start = 0;
    data.length = Number.MAX_SAFE_INTEGER;

    dt.one("preDraw", function (e, settings) {
      // Call the original action function
      if (button[0].className.indexOf("buttons-excel") >= 0) {
        if (jQuery.fn.dataTable.ext.buttons.excelHtml5.available(dt, config)) {
          jQuery.fn.dataTable.ext.buttons.excelHtml5.action.call(
            self,
            e,
            dt,
            button,
            config
          );
        } else {
          jQuery.fn.dataTable.ext.buttons.excelFlash.action.call(
            self,
            e,
            dt,
            button,
            config
          );
        }
      } else if (button[0].className.indexOf("buttons-csv") >= 0) {
        if (jQuery.fn.dataTable.ext.buttons.csvHtml5.available(dt, config)) {
          jQuery.fn.dataTable.ext.buttons.csvHtml5.action.call(
            self,
            e,
            dt,
            button,
            config
          );
        } else {
          jQuery.fn.dataTable.ext.buttons.csvFlash.action.call(
            self,
            e,
            dt,
            button,
            config
          );
        }
      }

      dt.one("preXhr", function (e, s, data) {
        // DataTables thinks the first item displayed is index 0, but we're not drawing that.
        // Set the property to what it was before exporting.
        settings._iDisplayStart = oldStart;
        data.start = oldStart;
      });

      // Reload the grid with the original page. Otherwise, API functions like table.cell(this) don't work properly.
      setTimeout(dt.ajax.reload, 0);

      // Prevent rendering of the full data to the DOM
      return false;
    });
  });

  // Requery the server with the new one-time export settings
  dt.ajax.reload();
};

jQuery(document).ready(function ($) {
  function getContentFilters() {
    const filters = {};
    $("[id=filter_results] select").each(function () {
      const name = $(this).attr("name");
      const value = $(this).val();
      if (value !== "") {
        filters[name] = value;
      }
    });

    return filters;
  }

  var table_cache = {};

  function initContentTable($sel, filters) {
    var columns = [];
    $sel.find("th").each(function () {
      var slug = $(this).attr("data-slug");

      var push = {
        data: slug,
      };

      if (slug === "actions") {
        push["orderable"] = false;
      }

      columns.push(push);
    });
    $sel.AbDataTable({
      processing: true,
      serverSide: true,
      columns: columns,
      persistent: true,
      buttons: [
        {
          extend: "excel",
          text: "Excel",
          className: "buttons-excel",
          action: exportAction,
          exportOptions: {
            columns: "th:not(.no-export)",
          },
        },
        {
          extend: "csv",
          text: "CSV",
          className: "buttons-csv",
          action: exportAction,
          exportOptions: {
            columns: "th:not(.no-export)",
          },
        },
      ],
      ajax: function (data, callback, settings) {
        data["type"] = $sel.data("content-type");
        data["hide-content"] = $sel.data("hide-content");
        data["filters"] = filters;
        if (abUser.id !== undefined) {
          data["override_user_id"] = abUser.id;
        }

        // The draw key denotes the current "iteration" of the datatable and increments with every update.
        // This will ruin the cache key since it will always change. Make a new object and delete the draw key.
        var key_data = Object.assign({}, data);
        delete key_data["draw"];

        const key = btoa(JSON.stringify(key_data));
        if (table_cache[key] !== undefined) {
          callback(table_cache[key]);
          return true;
        }

        const get_content = api.get("content", data);

        get_content.done(function (r) {
          const required = [
            "data",
            "length",
            "recordsFiltered",
            "recordsTotal",
          ];

          jQuery.each(required, function (ind, val) {
            if (r[val] === undefined) {
              console.error(r);
              throw "Required [" + val + "] not found in response";
            }
          });

          jQuery.each(r.data, function (ind, val) {
            var postTitle = $("<div>").text(val["post_title"]).html();
            var url = "/?post_type=" + val["post_type"] + "&p=" + val["id"];
            r.data[ind]["post_title"] =
              '<a href="' + url + '">' + postTitle + "</a>";
            r.data[ind]["actions"] =
              '<a href="/wp-admin/post.php?post=' +
              val["id"] +
              '&action=edit">Edit</a>';
          });
          // We delete draw before we store, since the draw of the cached version will be behind the actual draw iteration
          // and when that is the case dataTables chokes
          delete r.draw;

          table_cache[key] = r;
          callback(r);
        });
      },
    });
  }

  // sortable tables
  $(".default_sort").AbDataTable();

  $(".notification_table").AbDataTable({
    order: [[1, "desc"]],
  });

  $(".points_table").AbDataTable({
    order: [[2, "desc"]],
  });

  // my team table
  $(".my_team_table").AbDataTable({
    columnDefs: [{ orderable: false, targets: 0 }],
  });

  // my team table
  $(".directory_table").AbDataTable({
    buttons: [],
    order: [1, "asc"],
    columnDefs: [{ targets: [0, 2], orderable: false, searchable: false }],
  });

  // my team table
  $(".colabs_table").AbDataTable({
    buttons: [],
    columnDefs: [{ orderable: false, targets: 0 }],
  });

  $(".campaigns_table").AbDataTable({
    buttons: [],
    order: [[0, "desc"]],
    columns: [
      {
        targets: 0,
        type: "date",
        dateFormat: "mm/dd/yyyy",
        visible: true,
        search: {
          search: "",
          smart: true,
          regex: false,
          caseInsensitive: true,
        },
      },
      {
        visible: true,
        search: {
          search: "",
          smart: true,
          regex: false,
          caseInsensitive: true,
        },
      },
      {
        visible: true,
        search: {
          search: "",
          smart: true,
          regex: false,
          caseInsensitive: true,
        },
      },
      {
        visible: true,
        search: {
          search: "",
          smart: true,
          regex: false,
          caseInsensitive: true,
        },
      },
      {
        visible: true,
        search: {
          search: "",
          smart: true,
          regex: false,
          caseInsensitive: true,
        },
      },
    ],
  });

  $(".prospects_table").AbDataTable({
    buttons: [],
    order: [[0, "desc"]],
  });

  $(".report_display").AbDataTable({
    buttons: ["colvis", "excelHtml5", "csvHtml5"],
  });

  $(".saved_reports").AbDataTable({
    buttons: [],
    columnDefs: [{ orderable: false, targets: 1 }],
  });

  $(".posts_table").AbDataTable({
    columnDefs: [
      {
        targets: [0, 1],
        visible: true,
      },
      {
        targets: 2,
        type: "date",
        dateFormat: "mm/dd/yyyy",
        visible: true,
      },
    ],
  });

  $(".public_playbooks .my_playbooks .my_company_playbooks").AbDataTable({
    columnDefs: [
      {
        targets: [0, 1, 2, 4],
        visible: true,
      },
      {
        targets: 3,
        type: "date",
        dateFormat: "mm/dd/yyyy",
        visible: true,
      },
    ],
  });

  // add custom placeholder text to DataTables search input
  $(".dataTables_filter input").attr("placeholder", "search");

  // adds a scrollable wrapper to tables
  $("table.scrollX").wrap('<div class="data_table_scroll_x"></div>');

  if ($(".content-data-table").length > 0) {
    const validate_token = api.validateToken();
    validate_token.done(function () {
      const filters = getContentFilters();

      $(".content-data-table").each(function () {
        const $sel = $(this);
        initContentTable($sel, filters);
      });
    });
  }
});
