/*
 *	Javasciprt for Allbound Log
 */

var APPLICATION_URL =
  "http://" + window.location.host + "/wp-admin/admin-ajax.php";

function ab_like(
  var_user_id,
  var_resource_id,
  var_action_preformed,
  isCompanyPlaybookSection
) {
  // get values or set defaults
  //var_user_id = typeof var_user_id !== 'undefined' ? var_user_id : 0;
  //var_resource_id = typeof var_resource_id !== 'undefined' ? var_resource_id : 0;
  //var_action_preformed = typeof var_action_preformed !== 'undefined' ? var_action_preformed : 'add';

  jQuery.ajax({
    url: APPLICATION_URL,
    async: false,
    data: {
      action: "ab_like",
      user_id: var_user_id,
      resource_id: var_resource_id,
      action_performed: var_action_preformed,
      reset_company_playbook_cache: isCompanyPlaybookSection,
    },
    type: "POST",
    success: function (response) {
      //log successful
      result = response;
    },
    error: function (request, status, error) {
      console.log("returned error");
      //showMessage('Sorry, there was an error connecting. Please try again later.','OK');
    },
  });
}

/**
 * _likeContent
 * local function that allows posts content to be liked <3
 * @private
 */
function _likeContent() {
  // toggle class, tooltip attributes and action performed
  jQuery("span.likes").on("click", function () {
    action = jQuery(this).attr("data-log-action");
    var count = jQuery(".counter_likes").html();
    if (action == "added_to_likes") {
      // increase the counter by 1
      jQuery(".counter_likes").html(++count);
    } else if (action == "removed_from_likes") {
      // decrease the counter by 1
      jQuery(".counter_likes").html(--count);
    }

    var isCompanyPlaybookSection;

    if (jQuery(this).closest(".pinned_content_with_playbook")?.length > 0) {
      isCompanyPlaybookSection = true;
    }

    ab_like(
      ab_user_id,
      jQuery(this).attr("data-log-resource-id"),
      jQuery(this).attr("data-log-action"),
      isCompanyPlaybookSection
    );

    // toggle the tooltip title
    /*
      jQuery(this).attr("title") this values is always undefined
    */
    // jQuery(this).attr(
    //   "title",
    //   jQuery(this).attr("title") == "Like" ? "Unlike" : "Like"
    // );

    // toggle the action
    jQuery(this).attr(
      "data-log-action",
      jQuery(this).attr("data-log-action") == "added_to_likes"
        ? "removed_from_likes"
        : "added_to_likes"
    );

    jQuery(this).toggleClass("liked");

    jQuery(this).tipTip({
      defaultPosition: "top",
      maxWidth: "300px",
      delay: 100,
      fadeIn: 200,
      fadeOut: 400,
      content: action == "added_to_likes" ? "Unlike" : "Like",
    });

    return false;
  });
}

jQuery(document).ready(function ($) {
  // call the like content function
  _likeContent();
});
