jQuery(document).ready(function ($) {
  //Singletons for available resources and actions
  var available_resources = (function () {
    var resources;

    function setResources() {
      jQuery.ajax({
        url: ajaxurl,
        data: {
          action: "ab_get_resources",
        },
        dataType: "json",
        async: false,
        cache: false,
        success: function (data) {
          resources = data;
        },
      });
      return resources;
    }

    return {
      getResources: function () {
        return resources || setResources();
      },
    };
  })();

  var available_actions = (function () {
    var actions;

    function setActions() {
      jQuery.ajax({
        url: ajaxurl,
        data: {
          action: "ab_get_actions",
        },
        dataType: "json",
        async: false,
        cache: false,
        success: function (data) {
          actions = data;
        },
      });
      return actions;
    }

    return {
      getActions: function () {
        return actions || setActions();
      },
    };
  })();

  var available_account_types = (function () {
    var accountTypes;
    function setAccountTypes() {
      jQuery.ajax({
        url: ajaxurl,
        data: {
          action: "get_account_types",
        },
        dataType: "json",
        async: false,
        cache: false,
        success: function (data) {
          accountTypes = data?.account_types || [];
        },
      });
      return accountTypes;
    }

    return {
      getAccountTypes: function () {
        return accountTypes || setAccountTypes();
      },
    };
  })();

  // Remove Group Member from Groups Page
  $(".remove-group-user").on("click", function (e) {
    var member_id = $(this).data("id");
    var group_id = $(this).data("group");
    var $sel = $(this);

    $sel.attr("disabled", "disabled");

    $.ajax({
      url: "/allbound-settings/?app=groups",
      type: "post",
      dataType: "json",
      data: {
        group_id: group_id,
        member_ids: [member_id],
        form_type: "group_members",
      },
      success: function (r) {
        if (r.status == 0) {
          var dt = $sel.closest("table").DataTable();
          dt.row('.group-member[data-id="' + member_id + '"]')
            .remove()
            .draw();
          if ($(".group-member").length == 0) {
            var $members_table = $("#form_group_members");
            $(".empty-members-res").show();
            $members_table.remove();
            $("[id=tiptip_holder]").hide();
          }
        } else {
          $sel.removeAttr("disabled");
        }
      },
    });
  });

  //group-rules control
  $("select.group_type")
    .change(function (e) {
      var type = $(this).val();
      if (type === "dynamic") {
        $(".add_group_rules").show();
        $(".add_group_additional_rules").show();
      } else {
        $(".add_group_rules").hide();
        $(".add_group_additional_rules").hide();
      }
    })
    .trigger("change");

  $("select.group_add_rule").change(function (e) {
    var rule = $(this).val();
    var rule_type = "";
    if ($(this).attr("name") === "add_additional_rule[]") {
      rule_type = "_additional";
    }
    var select_action = $(this).parent().children(".group_add_action");
    var actions = available_actions.getActions();
    var options_action = "<option value=''>-- Select Action --</option>";
    var keys = Object.keys(actions[rule]);

    for (var i = 0; i < keys.length; i++) {
      options_action +=
        "<option value='" +
        keys[i] +
        "'>" +
        actions[rule][keys[i]] +
        "</option>";
    }
    select_action.html(options_action);
    $(
      "option[value=is_like]",
      $(this).parent().children(".add_operator")
    ).removeAttr("disabled");
    $(
      "option[value=not_like]",
      $(this).parent().children(".add_operator")
    ).removeAttr("disabled");
    $("option[value=equals]", $(this).parent().children(".add_operator")).html(
      "Exactly Matches"
    );
    $(
      "option[value=not_equals]",
      $(this).parent().children(".add_operator")
    ).html("Does Not Match");
    $("option", $(this).parent().children(".add_operator")).prop(
      "selected",
      false
    );
    $("option[value=is_like]", $(this).parent().children(".add_operator")).prop(
      "selected",
      true
    );
    var field_definition = "";
    if (rule == "system" || rule == "lead" || rule == "deal") {
      field_definition =
        "<input class='group_add_value' type='hidden' name='add" +
        rule_type +
        "_value[]' value='*' />";
      $(
        "option[value=is_like]",
        $(this).parent().children(".add_operator")
      ).attr("disabled", "disabled");
      $(
        "option[value=not_like]",
        $(this).parent().children(".add_operator")
      ).attr("disabled", "disabled");
      $("option", $(this).parent().children(".add_operator")).prop(
        "selected",
        false
      );
      $(
        "option[value=equals]",
        $(this).parent().children(".add_operator")
      ).prop("selected", true);
      $(
        "option[value=equals]",
        $(this).parent().children(".add_operator")
      ).html("True");
      $(
        "option[value=not_equals]",
        $(this).parent().children(".add_operator")
      ).html("False");
    } else {
      if (rule == "company" || rule == "user") {
        field_definition =
          "<input class='group_add_value' type='text' name='add" +
          rule_type +
          "_value[]' value='' />";
      } else {
        $(
          "option[value=is_like]",
          $(this).parent().children(".add_operator")
        ).attr("disabled", "disabled");
        $(
          "option[value=not_like]",
          $(this).parent().children(".add_operator")
        ).attr("disabled", "disabled");
        $(
          "option[value=equals]",
          $(this).parent().children(".add_operator")
        ).prop("selected", true);
        var options_value = "<option value=''>-- Select Value --</option>";
        var select_open =
          "<select class='group_add_value' type='text' name='add" +
          rule_type +
          "_value[]'>";
        var select_end = "</select>";
        var values = available_resources.getResources();

        for (var i = 0; i < values[rule].length; i++) {
          options_value +=
            "<option value='" +
            values[rule][i].resource_id +
            "'>" +
            values[rule][i].title +
            "</option>";
        }
        field_definition = select_open + options_value + select_end;
      }
    }
    $(this).parent().children(".group_add_value").replaceWith(field_definition);
  });

  $("select.group_add_action").change(function (e) {
    var select_action = $(this).val();
    var select_rule = $(this).parent().children(".group_add_rule").val();
    if (select_rule === "company" && select_action === "ab_account_type") {
      var type = "";
      if ($(this).attr("name") === "add_additional_action[]") {
        type = "_additional";
      }

      $(
        "option[value=is_like]",
        $(this).parent().children(".add_operator")
      ).attr("disabled", "disabled");
      $(
        "option[value=not_like]",
        $(this).parent().children(".add_operator")
      ).attr("disabled", "disabled");
      $(
        "option[value=equals]",
        $(this).parent().children(".add_operator")
      ).prop("selected", true);

      var options_value = "<option value=''>-- Select Value --</option>";
      var select_open =
        "<select class='group_add_value' type='text' name='add" +
        type +
        "_value[]'>";
      var select_end = "</select>";

      var values = available_account_types.getAccountTypes();
      for (var i = 0; i < values.length; i++) {
        options_value +=
          "<option value='" +
          values[i].value +
          "'>" +
          values[i].label +
          "</option>";
      }

      field_definition = select_open + options_value + select_end;
      $(this)
        .parent()
        .children(".group_add_value")
        .replaceWith(field_definition);
    } else if (
      select_rule === "company" &&
      !(select_action === "ab_account_type")
    ) {
      var type = "";
      if ($(this).attr("name") === "add_additional_action[]") {
        type = "_additional";
      }
      $(
        "option[value=is_like]",
        $(this).parent().children(".add_operator")
      ).removeAttr("disabled");
      $(
        "option[value=not_like]",
        $(this).parent().children(".add_operator")
      ).removeAttr("disabled");
      $(
        "option[value=is_like]",
        $(this).parent().children(".add_operator")
      ).prop("selected", true);

      field_definition =
        "<input class='group_add_value' type='text' name='add" +
        type +
        "_value[]' value='' />";
      $(this)
        .parent()
        .children(".group_add_value")
        .replaceWith(field_definition);
    }
  });

  var reporTitle = {
    user_registered_per_month: {
      label: "Registered users",
      color: {
        fill: "rgba(75,185,128,0.4)",
        stroke: "rgba(75,185,128,1)",
        point: "rgba(75,185,128,1)",
      },
    },
    new_user_registered_per_month: {
      label: "New users",
      color: {
        fill: "rgba(75,185,128,0.4)",
        stroke: "rgba(75,185,128,1)",
        point: "rgba(75,185,128,1)",
      },
    },
    user_actions_per_month: {
      label: "Users actions",
      color: {
        fill: "rgba(42,145,211,0.4)",
        stroke: "rgba(42,145,211,1)",
        point: "rgba(42,145,211,1)",
      },
    },
    companies_per_group: {
      label: "Companies",
      color: {
        fill: "rgba(70,29,124,0.4)",
        stroke: "rgba(70,29,124,1)",
        point: "rgba(70,29,124,1)",
      },
    },
    deals_per_month: {
      label: "New deals",
      color: {
        fill: "rgba(247,172,32,0.4)",
        stroke: "rgba(247,172,32,1)",
        point: "rgba(247,172,32,1)",
      },
    },
    pipeline_per_month: {
      label: "Pipeline",
      color: {
        fill: "rgba(238,85,28,0.4)",
        stroke: "rgba(238,85,28,1)",
        point: "rgba(238,85,28,1)",
      },
    },
    deals_closed_per_month: {
      label: "Closed deals",
      color: {
        fill: "rgba(240,245,44,0.4)",
        stroke: "rgba(240,245,44,1)",
        point: "rgba(240,245,44,1)",
      },
    },
  };

  function ab_get_group_report_data(
    group_id,
    report_type,
    start_date,
    end_date
  ) {
    if (!start_date) {
      start_date = "";
    }

    if (!end_date) {
      end_date = "";
    }

    jQuery.ajax({
      url: "https://" + window.location.host + "/wp-admin/admin-ajax.php",
      async: true,
      data: {
        action: "ab_get_group_report_data",
        original_object_id: group_id,
        report_type: report_type,
        start_date: start_date,
        end_date: end_date,
      },
      type: "GET",
      success: function (response) {
        var ChartLabels = new Array();
        var ChartData = new Array();
        var responseInfo = JSON.parse(response);

        if (responseInfo && responseInfo[group_id].length > 0) {
          var chartInfo = _format_user_actions_object(
            responseInfo[group_id],
            start_date,
            end_date
          );
          ChartLabels = chartInfo.labels;
          ChartData = chartInfo.data;
        } else {
          var dateTime = new Date();
          ChartLabels.push(start_date);
          ChartData.push(0);
          ChartLabels.push(end_date);
          ChartData.push(0);
        }
        var lineChartData = {
          labels: ChartLabels,
          datasets: [
            {
              label: reporTitle[report_type].label,
              fillColor: reporTitle[report_type].color.fill,
              strokeColor: reporTitle[report_type].color.stroke,
              pointColor: reporTitle[report_type].color.point,
              pointStrokeColor: "#fff",
              pointHighlightFill: "#fff",
              pointHighlightStroke: "rgba(247,172,32,1)",
              data: ChartData,
            },
          ],
        };
        $(".filter_holder").show();
        $(".chart_holder").show();
        ab_set_group_report_chart(lineChartData);
        $(".table_holder").show();
        ab_set_group_report_table(group_id, report_type, JSON.parse(response));
      },
      error: function (request, status, error) {
        console.log(error);
      },
    });
  }

  function ab_set_group_report_chart(lineChartData) {
    var ctx = document.getElementById("line_chart").getContext("2d");
    if (window.myLine) {
      window.myLine.destroy();
    }
    window.myLine = new Chart(ctx).Line(lineChartData, {
      responsive: true,
      bezierCurve: true,
      datasetStrokeWidth: 8,
      pointDotRadius: 6,
      pointDotStrokeWidth: 2,
      tooltipFontSize: 12,
      multiTooltipTemplate: "<%= datasetLabel %> - <%= value %>",
      maintainAspectRatio: false,
      scaleFontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    });

    jQuery(".line_legend").html(window.myLine.generateLegend());
  }

  var reportTableDef = {
    user_actions_per_month: {
      columns: [
        {
          data: "title",
          title: "Resource",
          fnCreatedCell: function (nTd, sData, oData, iRow, iCol) {
            $(nTd).html(
              "<a href='/" +
                oData.type +
                "/" +
                oData.slug +
                "'>" +
                oData.title +
                "</a>"
            );
          },
        },
        {
          data: "type",
          title: "Type",
          fnCreatedCell: function (nTd, sData, oData, iRow, iCol) {
            var typeName = "";
            if (oData.type) {
              typeName = oData.type.replace(/_/g, " ");
              typeName = typeName.charAt(0).toUpperCase() + typeName.slice(1);
            }
            $(nTd).html(typeName);
          },
        },
        {
          data: "action",
          title: "Action",
          fnCreatedCell: function (nTd, sData, oData, iRow, iCol) {
            var actionName = "";
            if (oData.action) {
              actionName = oData.action.replace(/_/g, " ");
              actionName =
                actionName.charAt(0).toUpperCase() + actionName.slice(1);
            }
            $(nTd).html(actionName);
          },
        },
        {
          data: "user_name",
          title: "User",
          fnCreatedCell: function (nTd, sData, oData, iRow, iCol) {
            $(nTd).html(
              "<a href='/wp-admin/user-edit.php?user_id=" +
                oData.user_id +
                "'>" +
                oData.user_name +
                "</a>"
            );
          },
        },
        { data: "timestamp", title: "Date" },
      ],
      order: [[4, "desc"]],
    },
    user_registered_per_month: {
      columns: [
        {
          data: "full_name",
          title: "Name",
          fnCreatedCell: function (nTd, sData, oData, iRow, iCol) {
            $(nTd).html(
              "<a href='/wp-admin/user-edit.php?user_id=" +
                oData.user_id +
                "'>" +
                oData.full_name +
                "</a>"
            );
          },
        },
        {
          data: "company",
          title: "Company",
          fnCreatedCell: function (nTd, sData, oData, iRow, iCol) {
            var companyLink = "";
            if (oData.company_id) {
              companyLink =
                "<a href='?app=companies&view=company_view&id=" +
                oData.company_id +
                "'>" +
                oData.company +
                "</a>";
            }
            $(nTd).html(companyLink);
          },
        },
        {
          data: "role",
          title: "Role",
          fnCreatedCell: function (nTd, sData, oData, iRow, iCol) {
            var userRole = "";
            if (oData.company && oData.role) {
              userRole = oData.role.replace("_", " ");
              userRole = userRole.charAt(0).toUpperCase() + userRole.slice(1);
            }
            $(nTd).html(userRole);
          },
        },
        { data: "status", title: "Status" },
      ],
      order: [[0, "asc"]],
    },
    new_user_registered_per_month: {
      columns: [
        {
          data: "full_name",
          title: "Name",
          fnCreatedCell: function (nTd, sData, oData, iRow, iCol) {
            $(nTd).html(
              "<a href='/wp-admin/user-edit.php?user_id=" +
                oData.user_id +
                "'>" +
                oData.full_name +
                "</a>"
            );
          },
        },
        {
          data: "company",
          title: "Company",
          fnCreatedCell: function (nTd, sData, oData, iRow, iCol) {
            var companyLink = "";
            if (oData.company_id) {
              companyLink =
                "<a href='?app=companies&view=company_view&id=" +
                oData.company_id +
                "'>" +
                oData.company +
                "</a>";
            }
            $(nTd).html(companyLink);
          },
        },
        {
          data: "role",
          title: "Role",
          fnCreatedCell: function (nTd, sData, oData, iRow, iCol) {
            var userRole = "";
            if (oData.role) {
              userRole = oData.role.replace("_", " ");
              userRole = userRole.charAt(0).toUpperCase() + userRole.slice(1);
            }
            $(nTd).html(userRole);
          },
        },
        { data: "status", title: "Status" },
      ],
      order: [[0, "asc"]],
    },
    companies_per_group: {
      columns: [
        {
          data: "name",
          title: "Company",
          fnCreatedCell: function (nTd, sData, oData, iRow, iCol) {
            var companyLink = "";
            if (oData.company_id) {
              companyLink =
                "<a href='?app=companies&view=company_view&id=" +
                oData.company_id +
                "'>" +
                oData.name +
                "</a>";
            }
            $(nTd).html(companyLink);
          },
        },
        { data: "last_updated", title: "Last updated" },
      ],
      order: [[0, "asc"]],
    },
    deals_per_month: {
      columns: [
        { data: "company", title: "Company" },
        { data: "amount", title: "Amount" },
        { data: "sales_rep", title: "Sales rep" },
        { data: "customer", title: "Customer" },
        { data: "status", title: "Status" },
        { data: "date", title: "Date created" },
      ],
      order: [[5, "asc"]],
    },
    pipeline_per_month: {
      columns: [
        { data: "company", title: "Company" },
        { data: "total", title: "Amount" },
        { data: "sales_rep", title: "Sales rep" },
        { data: "customer", title: "Customer" },
        { data: "status", title: "Status" },
        { data: "date", title: "Date created" },
      ],
      order: [[5, "asc"]],
    },
    deals_closed_per_month: {
      columns: [
        { data: "company", title: "Company" },
        { data: "amount", title: "Amount" },
        { data: "sales_rep", title: "Sales rep" },
        { data: "customer", title: "Customer" },
        { data: "date", title: "Date closed" },
      ],
      order: [[4, "asc"]],
    },
  };

  function ab_set_group_report_table(group_id, report_type, tableInfo) {
    var oTable = $("#group_info").dataTable();
    oTable.fnDestroy();
    $("#group_info").empty();
    if (!tableInfo) {
      tableInfo = { group_id: [] };
    }
    $("#group_info").DataTable({
      data: tableInfo[group_id],
      columns: reportTableDef[report_type].columns,
      order: reportTableDef[report_type].order,
      dom: "lBfrtip",
      buttons: ["excelHtml5", "csvHtml5"],
      stateSave: true,
      stateDuration: 0,
      oLanguage: {
        sSearch: "",
        sLengthMenu: "Show _MENU_",
        oPaginate: {
          sFirst: "&laquo;",
          sPrevious: "&lsaquo;",
          sLast: "&raquo;",
          sNext: "&rsaquo;",
        },
      },
    });
    $(".dataTables_filter input").attr("placeholder", "search");
  }

  // Formats response from ajax call to populate user actions chart
  function _format_user_actions_object(userActions, start_date, end_date) {
    var labels = new Array(),
      count = new Array(),
      datesQuantity = new Array(),
      data = {};

    var date = "";

    data[start_date] = { total: 0 };
    // Iterate object
    var chartInfo = Object.keys(userActions).map(function (value, index) {
      // Get item object
      var action = userActions[value];
      // Format datetime to date
      var dateTime = new Date(action.date + " 00:00:00");
      var day = 0,
        month = 0;
      if (dateTime.getMonth() + 1 > 9) {
        month = dateTime.getMonth() + 1;
      } else {
        month = "0" + (dateTime.getMonth() + 1);
      }
      if (dateTime.getDate() > 9) {
        day = dateTime.getDate();
      } else {
        day = "0" + dateTime.getDate();
      }
      date = dateTime.getFullYear() + "-" + month + "-" + day;

      if (data[date]) {
        if (action.total) {
          data[date].total =
            parseFloat(data[date].total) + parseFloat(action.total);
        } else {
          data[date].total = data[date].total + 1;
        }
      } else {
        if (action.total) {
          data[date] = { total: action.total };
        } else {
          data[date] = { total: 1 };
        }
      }
    });

    if (!data[end_date]) {
      data[end_date] = { total: 0 };
    }

    for (var key in data) {
      labels.push(key);
      count.push(data[key].total);
    }

    return {
      labels: labels,
      data: count,
    };
  }

  $(".group_report").click(function () {
    group_id = $(this).parent().attr("data-resource-id");
    report_type = $(this).attr("data-report-type");

    $(this).parent().children(".flex").removeClass("active");
    $(this).addClass("active");

    $("#group_report_report_type").val(report_type);
    var start_date = $("#group_report_start_date").val();
    var end_date = $("#group_report_end_date").val();

    ab_get_group_report_data(group_id, report_type, start_date, end_date);
  });

  $(".group_report_filters").submit(function (e) {
    e.preventDefault();
    var group_id = $("#group_report_group_id").val();
    var report_type = $("#group_report_report_type").val();
    var start_date = $("#group_report_start_date").val();
    var end_date = $("#group_report_end_date").val();

    ab_get_group_summary_data(group_id, start_date, end_date);

    if (report_type) {
      ab_get_group_report_data(group_id, report_type, start_date, end_date);
    }
  });
});

function ab_get_group_summary_data(group_id, start_date, end_date) {
  jQuery(document).ready(function ($) {
    if (!start_date) {
      start_date = "";
    }

    if (!end_date) {
      end_date = "";
    }

    jQuery.ajax({
      url: "https://" + window.location.host + "/wp-admin/admin-ajax.php",
      async: true,
      data: {
        action: "ab_get_group_report_data",
        original_object_id: group_id,
        report_type: "group_summary",
        start_date: start_date,
        end_date: end_date,
      },
      type: "GET",
      success: function (response) {
        var responseInfo = JSON.parse(response);

        var total_users = 0,
          new_users = 0,
          percentage_users = 0,
          actions = 0,
          companies = 0,
          new_deals = 0,
          pipeline = 0,
          close_deals = 0;

        if (responseInfo && responseInfo[group_id]) {
          new_users = responseInfo[group_id].last_users.total;
          total_users = responseInfo[group_id].users.total;
          if (new_users > 0 && responseInfo[group_id].users.total > 0) {
            percentage_users = Math.round(
              (parseFloat(new_users) * 100) /
                parseFloat(responseInfo[group_id].users.total)
            );
          }
          actions = responseInfo[group_id].actions.total;
          companies = responseInfo[group_id].companies.total;
          new_deals = responseInfo[group_id].deals.total;
          pipeline = responseInfo[group_id].pipeline.total;
          close_deals = responseInfo[group_id].deals_closed.total;
        }

        $(".total_users").children(".summary_value").html(total_users);
        $(".new_users").children(".summary_value").html(new_users);
        $(".percentage_users")
          .children(".summary_value")
          .html(percentage_users + "%");
        $(".new_actions").children(".summary_value").html(actions);
        $(".total_companies").children(".summary_value").html(companies);

        if ($("#group_report_deals").val() == 1) {
          $(".new_deals").children(".summary_value").html(new_deals);
          $(".total_pipeline")
            .children(".summary_value")
            .html("$" + pipeline);
          $(".close_deals").children(".summary_value").html(close_deals);
        }
      },
      error: function (request, status, error) {
        console.log(error);
      },
    });
  });
}
