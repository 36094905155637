var APPLICATION_URL =
  "http://" + window.location.host + "/wp-admin/admin-ajax.php";

/**
 * @param {string} to_output single string unencoded
 * this function is a global for all modules (non react) for sanitizing a HTML in single line.
 */
function escape_output(to_output) {
  return to_output
    .replace(/\&/g, "&amp;")
    .replace(/\</g, "&lt;")
    .replace(/\>/g, "&gt;")
    .replace(/\"/g, "&quot;")
    .replace(/\'/g, "&#x27")
    .replace(/\//g, "&#x2F");
}

// query parameters from the URL query string
function get_param(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regexS = "[\\?&]" + name + "=([^&#]*)";
  var regex = new RegExp(regexS);
  var results = regex.exec(window.location.href);
  if (results == null) return "";
  else return results[1];
}

var ab_loaded_containers = [];
function ab_handle_load_ajax(context) {
  var ab_load_url = jQuery(context).attr("data-load-url");
  var ab_user_related = jQuery(context).attr("data-load-user-related");
  var ab_load_container = jQuery(context).attr("data-load-container");
  var ab_callback = jQuery(context).attr("data-load-callback");
  // don't reload the content if it's already loaded
  if (ab_loaded_containers.indexOf(ab_load_container) === -1) {
    jQuery(ab_load_container).html(
      '<div class="loading">Loading Content</div>'
    );
    ab_load_ajax(ab_load_url, ab_user_related, ab_load_container, ab_callback);
  }
  ab_loaded_containers.push(ab_load_container);
}

/**
 * ab_load_ajax
 * function to load content via ajax
 * @param ab_load_url (full url for file to load)
 * @param ab_load_container (selector for container to load content)
 **/
function ab_load_ajax(
  ab_load_url,
  ab_user_related,
  ab_load_container,
  ab_callback_function
) {
  jQuery.ajax({
    url: APPLICATION_URL,
    async: true,
    data: {
      action: "ab_load_ajax",
      load_url: ab_load_url,
      load_user_related: ab_user_related,
      load_container: ab_load_container,
    },
    type: "POST",
    success: function (response) {
      //log successful
      result = response;
      jQuery(result.container).html(result.content);
      ab_callback(ab_callback_function, result);
    },
    error: function (request, status, error) {
      console.log("returned error");
      //showMessage('Sorry, there was an error connecting. Please try again later.','OK');
    },
  });
}

/**
 * Call function from string
 * @param callback
 * @param params
 */
function ab_callback(callback, params) {
  if (
    typeof callback == "string" &&
    callback.length > 0 &&
    typeof window[callback] === "function"
  ) {
    return window[callback](params);
  }
  return false;
}
function add_notifications_unread_icon() {
  if (jQuery("#slideout_notifications .notification_alert").length > 0) {
    jQuery("#ab_notifications").append(
      '<span class="notification_circle unread"></span>'
    );
    jQuery(".menu-notifications").append(
      '<span class="notification_circle unread"></span>'
    );
  }
}

/**
 * callback on notifications load. after the notification tray is populated we need to attach click events
 * CALLED BY ab_load_ajax ab_callback
 * @param result
 */
function notifications_callback(result) {
  add_notifications_unread_icon();
  jQuery("#box_tab_colabs .slideout_header").append(
    '<a class="inline hasTip" title="Add Co/Lab" href="#add_colab"><span class="icon-add"></span></a>'
  );
  // mark notifications as read
  jQuery(".notification_read").click(function () {
    ab_remove_notification(this, true);
  });

  // mark notification as read when clicking to view the notification
  jQuery(".notification_alert a").click(function () {
    ab_remove_notification(this, false);
  });

  // mark notifications as read
  jQuery(".mark_all_read").click(function () {
    jQuery("#slideout_notifications .notification_read").each(function () {
      ab_remove_notification(this, false);
    });
    ab_empty_notifications();
  });
}

/**
 * Mark notification as read and remove element.
 * @param context
 */
function ab_remove_notification(context, async) {
  var notification_id = jQuery(context).attr("data-notification-id");
  if (!notification_id) {
    return false;
  }
  ab_log_as_read(
    notification_id,
    function (read) {
      if (!read) {
        return false;
      }
      if (
        jQuery(".notifications_list_wrapper > .notification_alert").length === 1
      ) {
        ab_empty_notifications();
      } else {
        jQuery(context)
          .closest(".notification_alert")
          .slideUp(function () {
            jQuery(this).remove();
          });
      }
    },
    async
  );
}

/**
 * display empty notifications tray
 */
function ab_empty_notifications() {
  jQuery(".sidebar_slideout .notifications_list_wrapper").html(
    '<div class="empty_container">' +
      '<span class="icon-notification"></span><br />' +
      "You have no new notifications.<br />" +
      "One less thing to do today!" +
      "</div>"
  );
  jQuery("#ab_notifications .notification_circle").remove();
}

// function to duplicate an existing prospect page
function ab_duplicate_prospect_page(prospect_id) {
  jQuery.ajax({
    url: "https://" + window.location.host + "/wp-admin/admin-ajax.php",
    async: true,
    data: {
      action: "ab_duplicate_prospect_page",
      prospect_id: prospect_id,
    },
    type: "POST",
    success: function (response) {
      location.reload();
    },
    error: function (request, status, error) {
      console.log(error);
    },
  });
}

// function to delete a resource (currently used on the prospect page archive)
function ab_delete_resource(var_resource_id) {
  jQuery.ajax({
    url: "https://" + window.location.host + "/wp-admin/admin-ajax.php",
    async: false,
    data: {
      action: "ab_delete_resource",
      resource_id: var_resource_id,
    },
    type: "POST",
    success: function (response) {
      //log successful
      result = jQuery.parseJSON(response);
      console.log(result);
    },
    error: function (request, status, error) {
      console.log("returned error");
      //showMessage('Sorry, there was an error connecting. Please try again later.','OK');
    },
  });
}

// function to delete a related deal (currently used on the single prospect page)
function ab_unlink_related_deal(var_prospect_id, var_deal_id) {
  jQuery.ajax({
    url: APPLICATION_URL,
    async: false,
    data: {
      action: "ab_unlink_related_deal",
      prospect_id: var_prospect_id,
      deal_id: var_deal_id,
    },
    type: "POST",
    success: function (response) {
      //log successful
      result = jQuery.parseJSON(response);
      console.log(result);
      //ab_log(var_action_performed, var_resource_id, 0);
    },
    error: function (request, status, error) {
      console.log("returned error");
      //showMessage('Sorry, there was an error connecting. Please try again later.','OK');
    },
  });
}

function init_colorbox() {
  jQuery(document).ready(function ($) {
    $(".colorbox").colorbox({
      scalePhotos: true,
      maxWidth: "80%",
      maxHeight: "80%",
      transition: "elastic",
      opacity: 0.9,
    });
    $(".video").colorbox({
      iframe: true,
      innerWidth: 700,
      innerHeight: 395,
      opacity: 0.9,
      title: function () {
        return $(this).attr("modal-title");
      },
    });
    $(".vidyard").colorbox({
      inline: true,
      innerWidth: 700,
      innerHeight: 395,
      scrolling: false,
      opacity: 0.9,
      title: function () {
        return $(this).attr("modal-title");
      },
    });
    $(".inline").colorbox({ inline: true, maxWidth: "620px", opacity: 0.9 });
    // reload page after colorbox close
    $(".inline-reload").colorbox({
      inline: true,
      maxWidth: "620px",
      opacity: 0.9,
      onClosed: function () {
        document.location.reload(true);
      },
    });
    $(".iframe").colorbox({
      iframe: true,
      width: "90%",
      height: "85%",
      opacity: 0.9,
    });

    // call colorbox for paginated items in data tables
    $(".paginate_button ").click(function () {
      $(".inline").colorbox({ inline: true, maxWidth: "620px", opacity: 0.9 });
    });
    // confirm sandbox refresh before processing request
    $(".sandbox_refresh").click(function () {
      if (
        confirm(
          "Refreshing the Sandbox will overwrite ALL existing data in the Sandbox account with info from the live account. This action can not be undone. Are you sure you want to proceed?"
        )
      ) {
        $(this).colorbox({
          scalePhotos: true,
          maxWidth: "80%",
          maxHeight: "80%",
          transition: "elastic",
          opacity: 0.9,
        });
      } else {
        return false;
      }
    });
  });
}

function init_clipboard() {
  jQuery(document).ready(function ($) {
    // copy input text to clipboard
    var clip = new Clipboard(".copy_btn");
    $(".copy_btn").click(function () {
      clip.on("success", function (event) {
        console.log("Copied text to clipboard: " + event.text);
      });
      $(this).addClass("copied").val("Copied");
      $(this)
        .parents(".modal_body")
        .children(".modal_confirm")
        .addClass("active");
    });
  });
}

function handleProspectOptionsClick(e) {
  var optionsContainer = document.querySelector(
    ".edit_options.active .options_container"
  );
  var optionsButton = document.querySelector(".options_button");

  const clickedButton =
    e.target.classList.contains("options_button") ||
    optionsButton.contains(e.target);
  const clickedInsideContainer = optionsContainer.contains(e.target);
  if (!clickedInsideContainer && !clickedButton) {
    document.querySelector(".edit_options.active").classList.remove("active");
    closeEditOptions();
  }
}

function closeEditOptions() {
  window.removeEventListener("click", handleProspectOptionsClick);
}

function init_prospect_pages() {
  jQuery(document).ready(function ($) {
    $(".duplicate_prospect_page").on("click", function (e) {
      var prospect_id = $(this).attr("data-prospect-id");
      ab_duplicate_prospect_page(prospect_id);
    });

    // calls the ab_delete_resource function and then hides the parent table row on prospect page archive
    $(".delete-prospect_page").on("click", function () {
      if (confirm("Are you sure you want to delete this?")) {
        ab_resource_id = $(this).attr("data-resource-id");
        ab_delete_resource(ab_resource_id);
        $(this).closest("tr").hide();
      } else {
        return false;
      }
    });

    // unlink deal from prospect page (single prospect page)
    $(".unlink_related_deal").on("click", function (e) {
      e.preventDefault();
      var prospect_id = $(this).attr("data-prospect-id");
      var deal_id = $(this).attr("data-deal-id");
      if (window.confirm("Are you sure you want to unlink this item?")) {
        $(this).closest("div").slideToggle();
        ab_unlink_related_deal(prospect_id, deal_id);
      }
      return false;
    });
  });
}

function popup_nofitication(title, message, icon) {
  var html = "<div class='popup-notification'><div class='icon-remove'></div>";
  if (icon !== undefined) {
    html += "<div class='icon " + icon + "'></div>";
  }
  html += "<div class='title'>" + title + "</div>";
  html += "<div class='message'>" + message + "</div></div>";
  jQuery("body").append(html);
  jQuery(".popup-notification").animate(
    {
      right: "25px",
    },
    500
  );
  var close_notification = function () {
    jQuery(".popup-notification").animate(
      {
        right: "-300px",
      },
      500,
      function () {
        jQuery(this).remove();
      }
    );
  };
  setTimeout(close_notification, 20000);
  jQuery("body").on("click.ab_popup_notification", function () {
    close_notification();
    jQuery(this).off("click.ab_popup_notification");
  });
}
function dataTablesExportAction(e, dt, button, config) {
  {
    var self = this;
    var oldStart = dt.settings()[0]._iDisplayStart;

    dt.one("preXhr", function (e, s, data) {
      // Just this once, load all data from the server...
      data.start = 0;
      data.length = 2147483647;
      data.export = true;

      dt.one("preDraw", function (e, settings) {
        // Call the original action function
        if (button[0].className.indexOf("buttons-excel") >= 0) {
          if (
            jQuery.fn.dataTable.ext.buttons.excelHtml5.available(dt, config)
          ) {
            jQuery.fn.dataTable.ext.buttons.excelHtml5.action.call(
              self,
              e,
              dt,
              button,
              config
            );
          } else {
            jQuery.fn.dataTable.ext.buttons.excelFlash.action.call(
              self,
              e,
              dt,
              button,
              config
            );
          }
        } else if (button[0].className.indexOf("buttons-csv") >= 0) {
          if (jQuery.fn.dataTable.ext.buttons.csvHtml5.available(dt, config)) {
            jQuery.fn.dataTable.ext.buttons.csvHtml5.action.call(
              self,
              e,
              dt,
              button,
              config
            );
          } else {
            jQuery.fn.dataTable.ext.buttons.csvFlash.action.call(
              self,
              e,
              dt,
              button,
              config
            );
          }
        }

        dt.one("preXhr", function (e, s, data) {
          // DataTables thinks the first item displayed is index 0, but we're not drawing that.
          // Set the property to what it was before exporting.
          settings._iDisplayStart = oldStart;
          data.start = oldStart;
        });

        // Reload the grid with the original page. Otherwise, API functions like table.cell(this) don't work properly.
        setTimeout(dt.ajax.reload, 0);

        // Prevent rendering of the full data to the DOM
        return false;
      });
    });

    // Requery the server with the new one-time export settings
    dt.ajax.reload();
  }
}

function resendVerificationEmail(e, email) {
  const $ = jQuery;
  e.preventDefault();
  $(e.target).attr("disabled", "disabled");
  $(e.target).after('<p class="pf_loading">&nbsp;</p>');

  $.get(
    "/wp-admin/admin-ajax.php?action=ab_resend_verification&user_email=" +
      email,
    function (r) {
      if (r.status == true) {
        $(".pf_error").remove();
        $(".verify-email-button").remove();
        $(".pf_loading").remove();
        $('[data-js="verify_status"]')
          .removeClass("hidden")
          .html("Success! A new verification email has been sent.");
      } else {
        $(e.target).removeAttr("disabled");
      }
    }
  );
}

jQuery(document).ready(function ($) {
  init_colorbox();
  init_clipboard();
  init_prospect_pages();

  // Reload Prospect Page functions on pagination click
  $(document).on("click", ".paginate_button", function () {
    jQuery(function ($) {
      init_prospect_pages();
      init_colorbox();
      init_clipboard();
    });
  });

  $(".datepicker").datepicker({
    dateFormat: "yy-mm-dd",
    changeYear: true,
  });

  // triggers the ab_load_ajax function
  $(".load_ajax").click(function () {
    ab_handle_load_ajax(this);
  });

  $(".on_load_ajax").each(function () {
    ab_handle_load_ajax(this);
  });

  // add select2 styling to add pins multiple select
  $(".pin_select").select2({
    minimumResultsForSearch: 10,
    placeholder: "Select...",
  });

  // add select2 styling to add pins multiple select
  $(".report_options select").select2({
    minimumResultsForSearch: 10,
    placeholder: "Select...",
    allowClear: true,
  });

  $(".pipeline_filters select, .leaderboard_filters select").select2({
    minimumResultsForSearch: 20,
    width: "100%",
  });

  // Style select2 to all selects using this class
  $(".select-styled").select2({
    minimumResultsForSearch: Infinity,
    placeholder: "Select...",
    allowClear: true,
  });

  // Style select2 to all selects using this class
  $(".select-global-search").select2({
    minimumResultsForSearch: Infinity,
    placeholder: "All Types",
    allowClear: true,
  });

  $(".ui-sortable").sortable();

  /* media uploading*/
  $(document).on("click", ".clear-btn", function (e) {
    e.preventDefault();
    var id = $(this).attr("id");
    $("#" + id + "_url").val("");
  });

  $(document).on("click", ".upload-btn", function (e) {
    e.preventDefault();
    var id = $(this).attr("id");
    var image = wp
      .media({
        title: "Upload Media",
        // mutiple: true if you want to upload multiple files at once
        multiple: false,
      })
      .open()
      .on("select", function (e) {
        // This will return the selected image from the Media Uploader, the result is an object
        var uploaded_image = image.state().get("selection").first();
        // We convert uploaded_image to a JSON object to make accessing it easier
        // Output to the console uploaded_image
        console.log(uploaded_image);
        var media_url = uploaded_image.toJSON().url;
        // Let's assign the url value to the input field
        $("#" + id + "_url").val(media_url);
      });
  });

  // set placeholder title on the input and hide the label on the login form
  $(
    '.credentials_content form input:not([type="submit"] [type="select"])'
  ).each(function () {
    var placeholder_title = $(this).prev("label").html();
    $(this).prev("label").hide();
    $(this).attr("placeholder", placeholder_title);
  });

  // Set value of the checkbox in the hidden field placed just before it
  $(document).on(
    "change",
    "#dash_btn_new_tab, #dash_slider_new_tab, #dash_quick_link_new_tab",
    function () {
      var value = $(this).is(":checked") ? $(this).val() : 0;
      $(this).prev().val(value);
    }
  );

  // Set value of the multi-select dropdown in the hidden field placed just before it
  $(document).on(
    "change",
    "#dash_btn_group, #dash_slider_group, #dash_quick_link_group",
    function () {
      var value = $(this).val() !== null ? $(this).val() : "";
      $(this).prev().val(value);
    }
  );

  // duplicate row and unselect any selections
  function duplicateRow(selectElementObj, timeStamp) {
    var $this = selectElementObj;
    var timeStamp = timeStamp;
    if ($this.hasClass("group_row")) {
      // we need to set the second boolean to true for dynamic group rows to work
      var cln = $this.parent().clone(true, true);
    } else {
      var cln = $this.parent().clone(true, false);
    }
    //var cln = $this.parent().clone(true, false);
    if ($this.hasClass("media")) {
      //cln.find('input').attr('id',e.timeStamp+'_'+$(this).attr('id'));
      cln.find('input[type="text"]').attr("id", Math.round(timeStamp) + "_url");
      cln.find('input[type="button"]').attr("id", Math.round(timeStamp));
      cln
        .find(
          'input:not([type="button"]):not([type="checkbox"]), textarea, select'
        )
        .attr("value", "");
      cln.find(".colorbox").addClass("hidden");
    }
    // clear the values duplicated from the previous row
    cln.find('input[type="text"]').attr("value", "");
    cln.find("textarea").val("");
    cln.find('span[data-js="icon-display"]').removeClass();
    cln.find("option:selected").prop("selected", false);
    cln.find('input[type="radio"]').prop("checked", false);
    cln.find('input[type="checkbox"]').prop("checked", false);
    // Clear hidden fields before checkboxes
    var checkboxName = cln.find('input[type="checkbox"]').attr("id");
    cln
      .find('input[type="checkbox"]')
      .prev("#" + checkboxName + "_hidden")
      .prop("value", "0");

    // Clear hidden fields before multi-select dropdown
    var selectName = cln.find("select").last().attr("id");
    cln
      .find("select")
      .last()
      .prev("#" + selectName + "_hidden")
      .prop("value", "");

    if (cln.find("select").hasClass("select2-hidden-accessible")) {
      var select2 = true;
    }
    // remove the hidden class from the select
    cln.find("select").removeClass("select2-hidden-accessible");
    // remove the selected option from the select list
    cln.find("option:selected").prop("selected", false);
    // remove the existing select2 box
    cln.find("select").next("span.select2").remove();
    // append to the end of the list
    cln.insertAfter($this.parent());
    // initialize select2 function
    if (select2) {
      initializeSelect2(cln.find("select"));
    }
  }

  $(document).on("click", ".addrow", function (e) {
    e.preventDefault();
    var selectObj = $(this);
    var timeStamp = e.timeStamp;
    duplicateRow(selectObj, timeStamp);
  });

  $(document).on("click", ".deleterow", function (e) {
    e.preventDefault();
    var count = jQuery(this).parent().parent().children().length;
    if (count === 1) {
      duplicateRow(jQuery(this), e.timeStamp);
    }
    jQuery(this).parent().remove();
  });

  //function to initialize select2 for prospect pages
  function initializeSelect2(selectElementObj) {
    $this = selectElementObj;
    // allow the select item to maintain the order the options are selected in
    if ($this.attr("data-reorder")) {
      $this.on("select2:select", function (e) {
        var elm = e.params.data.element;
        $elm = jQuery(elm);
        $t = jQuery(this);
        $t.append($elm);
        $t.trigger("change.select2");
      });
    }
    // initialize select2 on the select object
    $this.select2({
      minimumResultsForSearch: 5,
      placeholder: "Select...",
    });
  }
  //onload: call the above initialize function for prospect pages
  $(".resource_select").each(function () {
    initializeSelect2($(this));
  });

  // accordion toggle script
  $(".ab_accordion_toggle").click(function (e) {
    e.preventDefault();
    var $this = $(this);

    if ($this.hasClass("active")) {
      $this.removeClass("active");
      $this.next(".ab_accordion_container").slideUp();
    } else {
      $this
        .closest(".ab_accordion")
        .find(".ab_accordion_toggle")
        .removeClass("active");
      $this
        .closest(".ab_accordion")
        .find(".ab_accordion_toggle")
        .next(".ab_accordion_container")
        .slideUp();
      $this.addClass("active");
      $this.next(".ab_accordion_container").slideToggle();
    }
  });

  // tooltip
  $("#header .hasTip").tipTip({
    defaultPosition: "bottom",
    maxWidth: "200px",
    delay: 100,
    fadeIn: 200,
    fadeOut: 400,
  });
  $(".main .hasTip").tipTip({
    defaultPosition: "top",
    maxWidth: "140px",
    delay: 100,
    fadeIn: 200,
    fadeOut: 200,
  });
  $("#slideout_notifications .hasTip, #slideout_colab .hasTip").tipTip({
    defaultPosition: "left",
    maxWidth: "200px",
    delay: 300,
    fadeIn: 200,
    fadeOut: 200,
  });

  // toggle mobile menu
  $(".menu_toggle").click(function () {
    $("body").toggleClass("mobile_menu_open");
    $(".menu_text").html($(".menu_text").text() == "Close" ? "" : "Close");
  });

  // close dashboard alert
  $(".dashboard_alert .icon-remove").click(function () {
    $(this).closest(".dashboard_alert").slideUp();
    ab_log_as_read($(this).attr("data-notification-id"), function (read) {});
  });

  // mark all notifications as read
  // new logic to update batch mark as read notifications
  // known issue
  /*$('.mark_all_read').click(function () {

    var notifications = [];
    $("#slideout_notifications .notification_read").each(function () {
      notifications.push($(this).attr('data-notification-id'));
    });

    // check if there are notifications to update
    if (notifications.length) {

      // trigger ajax to batch update notifications
      ab_batch_notification_read(notifications, function (e) {

        if (e.result.status_code == 200) {

          $('.sidebar_slideout .notifications_list_wrapper').html(
            '<div class="empty_container">' +
            '<span class="icon-notification"></span><br />' +
            'You have no new notifications.<br />' +
            'One less thing to do today!' +
            '</div>'
          );
          $('#ab_notifications .notification_circle.unread').remove();
        }
      });
    }
  });*/

  // toggle the notifications slideout
  $("#ab_notifications").click(function () {
    $("#slideout_notifications").toggleClass("active");
  });
  // hide when clicking off the notifications slideout
  $(document).on("click", function (event) {
    if (
      !$("#slideout_notifications").is(event.target) && // if the click target is not the slideout
      !$(event.target).closest("#slideout_notifications").length && // and if the click target is not a descendant of the slideout
      !$(event.target).closest("#ab_notifications").length // // and if the click target is not a descendant of the header icon
    ) {
      $("#slideout_notifications").removeClass("active"); // hide the slideout
    }
  });

  // toggle the colab slideout
  $("#ab_colab").click(function () {
    $("#slideout_colab").toggleClass("active");
    // only load the active tab content and trigger a click event to make the ajax load
    $("#slideout_colab")
      .find(".slideout_nav li.active")
      .children(".load_ajax")
      .click();
  });
  // hide when clicking off the notifications slideout
  $(document).on("click", function (event) {
    if (
      !$("#slideout_colab").is(event.target) && // if the click target is not the slideout
      !$(event.target).closest("#slideout_colab").length && // and if the click target is not a descendant of the slideout
      event.target.className != "paginate_button " &&
      event.target.className != "paginate_button previous" &&
      event.target.className != "paginate_button next" &&
      event.target.className != "btn load_ajax" &&
      event.target.className != "btn load_ajax isLoaded" &&
      !$(event.target).closest("#ab_colab").length && // // and if the click target is not a descendant of the header icon
      !$("#cboxOverlay, #cboxClose").is(event.target)
    ) {
      $("#slideout_colab").removeClass("active"); // hide the slideout
    }
  });

  // toggle the asset language list
  $(".btn.multi_lang").click(function () {
    $(this).next("ul").slideToggle(300);
  });

  // hide when clicking off the asset language list
  // this is temporary code for demo purposes on how multi-language might work
  $(document).on("click", function (event) {
    if (!$(event.target).closest(".btn.multi_lang").length) {
      $(".dd_list").slideUp(300);
    }
  });

  function getQueryParam(url, key) {
    try {
      const urlObj = new URL(url);
      const param = urlObj.searchParams.get(key);
      return param;
    } catch (e) {
      console.error("Invalid URL", e);
      return null;
    }
  }

  // change display view on archive pages
  $(".view_selector").click(function () {
    var thisone = this.hash;
    var queryString = "listView";

    $(this).addClass("active").siblings().removeClass("active");

    if ($(this).hasClass("grid_compact")) {
      $(this)
        .closest(".section_holder")
        .find(".asset_holder")
        .addClass("compact");
      queryString = "compactView";
    } else if ($(this).hasClass("grid_full")) {
      $(this)
        .closest(".section_holder")
        .find(".asset_holder")
        .removeClass("compact");
      queryString = "generalView";
    }

    $(this)
      .closest(".section_holder")
      .children(".view_options")
      .children(thisone)
      .show()
      .siblings(".view_display")
      .hide();

    $(".pagination a").each(function () {
      var _href = $(this).attr("href");
      _href = _href.split("?");
      $(this).attr("href", _href[0] + "?grid=" + queryString);
    });

    const playQueryParam = getQueryParam(window.location.href, "play");
    let queryParam = !!playQueryParam ? `&play=${playQueryParam}` : "";

    //Add query string
    if (history.pushState) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?grid=" +
        queryString +
        queryParam;
      window.history.pushState({ path: newurl }, "", newurl);
    }

    return false;
  });

  // tab containers (old tab code)
  /*
  $('.tab_nav li, .tab_content .tab_section').removeClass('active');
  $('.tab_nav li:first-child, .tab_content .tab_section:first-child').addClass('active');
  $('.tab_nav li:not(.link)').click(function(){
    var thisone = this.id;
    $(this).addClass('active').siblings().removeClass('active');
    $(this).closest('.tab_container').children('.tab_content').children('#box_tab_'+thisone).addClass('active').siblings('.tab_section').removeClass('active');
    return false;
  });
  */

  // toggle tabs by hash on page load

  $(function () {
    var hash = window.location.hash.slice(1);
    if (hash.length > 0) {
      hash = hash.split("/").join("\\/");
      $('.tab_nav li a[href="#' + hash + '"]')
        .closest("li")
        .addClass("active")
        .siblings()
        .removeClass("active");
      $(".tab_container")
        .find("#box_tab_" + hash)
        .addClass("active")
        .siblings(".tab_section")
        .removeClass("active");
    } else {
      $(
        ".tab_container .tab_nav li, .tab_container .tab_content .tab_section"
      ).removeClass("active");
      $(
        ".tab_container .tab_nav li:first-child, .tab_container .tab_content .tab_section:first-child"
      ).addClass("active");
    }
  });

  // toggle tabs on hash change on same page
  $(window).bind("hashchange", function () {
    //detect hash change
    var hash = window.location.hash.slice(1);
    if (hash.length > 0) {
      hash = hash.split("/").join("\\/");
      $('.tab_nav li a[href="#' + hash + '"]')
        .closest("li")
        .addClass("active")
        .siblings()
        .removeClass("active");
      $(".tab_container")
        .find("#box_tab_" + hash)
        .addClass("active")
        .siblings(".tab_section")
        .removeClass("active");
    } else {
      $(".tab_nav li, .tab_content .tab_section").removeClass("active");
      $(
        ".tab_nav li:first-child, .tab_content .tab_section:first-child"
      ).addClass("active");
    }
  });

  // custom sorting of the dashboard
  $("#dashboard_sort").sortable({
    //handle: ".ui-icon",
    placeholder: "ui-sortable-placeholder",
    update: function (event, ui) {
      var order = $("#dashboard_sort").sortable("toArray", { attribute: "id" });
      console.log(order);

      jQuery.ajax({
        url: "https://" + window.location.host + "/wp-admin/admin-ajax.php",
        async: false,
        data: {
          action: "ab_dashboard_sort",
          user_id: ab_user_id,
          dashboard: "dashboard",
          widgets: order,
        },
        type: "POST",
        success: function (response) {
          result = jQuery.parseJSON(response);
          console.log(result);
        },
        error: function (request, status, error) {
          console.log("returned error");
        },
      });
    },
  });
  $("#dashboard_sort").disableSelection();

  // input field focus effect for the search box
  $(".s").focus(function () {
    $(this).removeClass("idleField").addClass("focusField");
    if (this.value == this.defaultValue) {
      this.value = "";
    }

    if (this.value != this.defaultValue) {
      this.select();
    }
  });
  $(".s").blur(function () {
    $(this).removeClass("focusField").addClass("idleField");
    if (this.value == "") {
      this.value = this.defaultValue;
    }
  });

  // Customize the select list styles (https://select2.github.io/)
  if ($(window).width() > 600) {
    $("#filter_results select, .action select").select2({
      minimumResultsForSearch: 30,
    });

    $("#filter_results select").on("change", function () {
      $("#filter_results select").select2("destroy");
      $("#filter_results select").select2({
        minimumResultsForSearch: 30,
      });
    });
  }

  // adds a scrollable wrapper to tables
  $(".single_overview .entry table").wrap(
    '<div class="data_table_scroll_x"></div>'
  );

  // toggle checked state on checkboxes with select all field
  $('table input[type="checkbox"].select_all:checked').click(function () {
    $('table td input[type="checkbox"]').prop("checked", false);
  });

  // function to narrow down the filter options on the main archive pages
  function ab_narrow_filters(post_type, slug) {
    jQuery.ajax({
      url: "https://" + window.location.host + "/wp-admin/admin-ajax.php",
      async: false,
      data: {
        action: "ab_narrow_filters",
        post_type: post_type,
        slug: slug,
      },
      type: "POST",
      success: function (response) {
        result = jQuery.parseJSON(response);
        //console.log(result);
      },
      error: function (request, status, error) {
        console.log("returned error");
      },
    });

    return result;
  }

  function filter_select_options() {
    var foo = [];
    $("#filter_results select").each(function (i, selected) {
      if ($(selected).val() != "") {
        foo[i] = $(selected).val();
      }
    });
    //console.log(foo);
    var keepers = ab_narrow_filters(ab_post_type, foo);

    console.log(keepers);
    //console.log($.isArray(keepers));

    $("#filter_results option").each(function () {
      //console.log( $(this).val() );
      //console.log($.inArray( $(this).val(), keepers));
      if ($(this).val() != "" && $.inArray($(this).val(), keepers) == -1) {
        console.log($(this).val());
        $(this).attr("disabled", "disabled");
      }
    });
  }

  // load the filter_select_options function only on the filtered results page
  // if we load it on page load it returns no results and disables all of the filter options
  $(window).on("load", function (e) {
    filtered = get_param("filtered");
    if (filtered) {
      filter_select_options();
    }
  });

  // run the filter_select_options function when a new option is selected from the filter dropdown menu
  $("#filter_results select").on("change", function (e) {
    filter_select_options();
    $(this).children("option").first().attr("disabled", "disabled");
  });

  //function to return the color and label for the diferent action categories
  function ab_get_point_action_asset(point_action) {
    var action_colors = {
      1: ["#4197c8", "#90c2df"],
      2: ["#724879", "#a778af"],
      3: ["#c83793", "#d76fb1"],
      4: ["#c84541", "#d87b78"],
      5: ["#f1b434", "#f8d793"],
      6: ["#5bb376", "#a1d3b0"],
      7: ["#fcba14", "#fdd774"],
      8: ["#fc14fa", "#fd74fc"],
      9: ["#1bd6dc", "#6ce9ed"],
      10: ["#1761d6", "#6198ee"],
      11: ["#007980", "#00c3ce"],
      12: ["#800039", "#e20065"],
      13: ["#398000", "#65e200"],
      14: ["#470080", "#7d00e2"],
      15: ["#008047", "#00e27d"],
    };

    return action_colors[Math.floor(Math.random() * 15) + 1];
  }

  // Ajax to get user info and points breakdown to update chart on leaderboard page.
  function ab_get_user_points_breakdown(user_id, ranking) {
    jQuery.ajax({
      url: "https://" + window.location.host + "/wp-admin/admin-ajax.php",
      async: true,
      data: {
        action: "ab_get_user_points_breakdown",
        user_id: user_id,
      },
      type: "GET",
      success: function (response) {
        var doughnutData = (colorInfo = []);
        var pointsInfo = JSON.parse(response);

        var userDetails = document.getElementsByClassName(
          "leaderboard_user_details"
        );
        if (userDetails[0]) {
          userDetails[0].innerHTML =
            pointsInfo.user_data.avatar +
            "<h3>" +
            escape_output(pointsInfo.user_data.name) +
            "</h3>" +
            pointsInfo.user_data.title +
            "<br />" +
            pointsInfo.user_data.company +
            "<br />" +
            "<a href='mailto:" +
            pointsInfo.user_data.email +
            "'>" +
            pointsInfo.user_data.email +
            "</a><br />" +
            (pointsInfo.user_data.office_phone || "");
        }
        var chartLegend = document.getElementsByClassName("legend");
        if (chartLegend[0]) {
          chartLegend[0].innerHTML =
            "<p>" +
            "<strong>Ranking:</strong> " +
            ranking +
            "<br />" +
            "<strong>Total Points:</strong> " +
            pointsInfo.user_data.total_points +
            "<br />" +
            "<strong>Status:</strong> " +
            pointsInfo.user_data.level +
            "</p>" +
            "<div class='doughnut_legend'></div>";
        }
        for (var i = 0; i < pointsInfo.points_breakdown.length; i++) {
          actionAsset = ab_get_point_action_asset();
          doughnutData.push({
            value: pointsInfo.points_breakdown[i].total_points,
            color: actionAsset[0],
            highlight: actionAsset[1],
            label: pointsInfo.points_breakdown[i].action_title,
          });
        }
        ab_set_points_breakdown_chart(doughnutData);
      },
      error: function (request, status, error) {
        console.log(error);
      },
    });
  }

  //function to update the points chart on leaderboard page
  function ab_set_points_breakdown_chart(doughnutData) {
    //doughnutData array
    // [
    //	{
    //   value: <<int>>,
    //   color: <<color-hex>>,
    //   highlight: <<color-hex>>,
    //   label: <<String for legend>>
    //  },
    //  {...}
    // ]
    var ctx = document.getElementById("doughnut_chart").getContext("2d");
    if (window.myDoughnut) {
      window.myDoughnut.destroy();
    }
    window.myDoughnut = new Chart(ctx).Doughnut(doughnutData, {
      responsive: true,
      bezierCurve: true,
      multiTooltipTemplate: "<%= datasetLabel %> - <%= value %>",
      maintainAspectRatio: false,
      percentageInnerCutout: 50,
      scaleFontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      tooltipFontSize: 12,
      tooltipFontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      legendTemplate:
        '<ul class="<%=name.toLowerCase()%>-legend"><% for (var i=0; i<segments.length; i++){%><li><span style="background-color:<%=segments[i].fillColor%>"></span><%if(segments[i].label){%><%=segments[i].label%><%}%></li><%}%></ul>',
    });

    jQuery(".doughnut_legend").html(window.myDoughnut.generateLegend());
  }

  // toggle user
  $(".user_toggle").on("click", function (e) {
    $(this).html(
      $(this).text() == "(activate)" ? "(deactivate)" : "(activate)"
    );
    $(this).closest("tr").toggleClass("inactive");
    var id = $(this).attr("data-id");
    jQuery.ajax({
      url: "https://" + window.location.host + "/wp-admin/admin-ajax.php",
      async: true,
      data: {
        action: "ab_toggle_user",
        user_id: id,
      },
      type: "POST",
      success: function (response) {},
      error: function (request, status, error) {
        console.log(error);
      },
    });
  });

  $(".leaderboard_table").AbDataTable({
    order: [[1, "asc"]],
    columnDefs: [{ orderable: false, targets: [0, 2] }],
    createdRow: function (nRow, aData, iDisplayIndex) {
      $(nRow).click(function () {
        user_id = aData[0];
        ranking = aData[1];
        ab_get_user_points_breakdown(user_id, ranking);
        $(".leaderboard_table tbody tr").removeClass("selected");
        $(this).addClass("selected");
      });
    },
  });

  // Function for files in Allbound (currently used on single-user_campaign
  // (Not sure if we need this. There is another ab_file function in the functions_campaign.php)
  function ab_file(var_file_id, var_parent_id) {
    var_file_id = typeof var_file_id !== "undefined" ? var_file_id : 0;
    var_parent_id = typeof var_parent_id !== "undefined" ? var_parent_id : 0;

    jQuery.ajax({
      url: "https://" + window.location.host + "/wp-admin/admin-ajax.php",
      async: false,
      data: {
        action: "ab_file",
        user_id: ab_user_id,
        file_id: var_file_id,
        parent_id: var_parent_id,
      },
      type: "POST",
      success: function (response) {
        //log successful
        result = jQuery.parseJSON(response);
        console.log(result);
      },
      error: function (request, status, error) {
        console.log("returned error");
        //showMessage('Sorry, there was an error connecting. Please try again later.','OK');
      },
    });
  }

  // Remove media file with ajax
  $("a span.remove_media").on("click", function (e) {
    e.preventDefault();
    if (!confirm("Delete?")) return;
    var file = $(this).closest("li").attr("data-file-id");
    var parent = $(this).closest("li").attr("data-parent-id");
    ab_file(file, parent);
    $(this).closest("li").hide("slow");
    return false;
  });

  $("a.update-reports-data").on("click", function (e) {
    e.preventDefault();
    if (!$(this).hasClass("disabled")) {
      var answer = confirm(
        "This process would take a moment, Are you sure you want to continue?"
      );
      if (answer == true) {
        $(this).addClass("disabled");
        $(this).attr("disabled", "disabled");
        $(this).html("Updating ...");
        jQuery.ajax({
          url: "https://" + window.location.host + "/wp-admin/admin-ajax.php",
          async: false,
          data: {
            action: "ab_update_report_data",
          },
          type: "GET",
          success: function (response) {
            result = jQuery.parseJSON(response);
          },
          error: function (request, status, error) {
            console.log("returned error");
          },
        });
      }
    }
  });

  $(".del-utilities-cache").on("click", function (e) {
    e.preventDefault();
    var data = {};
    data["action"] = "ab_delete_cache";
    data["id"] = $(this).data("id") ? $(this).data("id") : "";
    data["ajax"] = true;

    $.ajax({
      url: APPLICATION_URL,
      async: false,
      data: data,
      type: "POST",
      success: function (response) {
        result = jQuery.parseJSON(response);
        if (result && result.status && result.status == 200) {
          $("#del-cache-message").text(result.message);
        }
      },
      error: function (request, status, error) {
        console.log("returned error");
      },
    });
  });

  function get_filter(filters, key) {
    const split_filters = filters.split("&");
    var retval = "";
    $.each(split_filters, function (ind, val) {
      const filter = val.split("=");
      if (filter[0] === key) {
        retval = filter[1];
      }
    });

    return retval;
  }

  function get_page_key(post_type) {
    return "tile_page_" + post_type;
  }

  function get_content(paginationAction, assetHolder, page) {
    var data = {};
    var data_links = {};
    data["action"] = "ab_get_page_content";
    data["filter"] = paginationAction.attr("data-post-filter");

    if (page !== undefined) {
      data["current_page"] = page;
    } else {
      data["current_page"] = paginationAction.attr("data-page");
    }

    data["post_per_page"] = paginationAction.attr("data-post-per-page");
    data["total"] = paginationAction.attr("data-total-pages");

    const filters = data["filter"];
    const post_type = get_filter(filters, "post_type");

    const page_key = get_page_key(post_type);
    cookies.set(page_key, data["current_page"], 1);

    $.ajax({
      url: APPLICATION_URL,
      dataType: "html",
      data: data,
      type: "GET",
      success: function (response) {
        assetHolder.html(response);
        paginationAction.parent().find("a").removeClass("current");
        paginationAction.addClass("current");
        init_colorbox();
        init_clipboard();
        init_ab_log();
        assetHolder.parent().css("opacity", 1);
      },
      error: function (request, status, error) {
        console.log("returned error");
        assetHolder.parent().css("opacity", 1);
      },
    }).then(function () {
      data_links = data;
      data_links["action"] = "ab_get_page_pagination";

      $.ajax({
        url: APPLICATION_URL,
        data: data,
        type: "GET",
        success: function (response) {
          paginationAction.parent().html(response);
          assetHolder.parent().css("opacity", 1);
        },
        error: function (request, status, error) {
          console.log("returned error");
          assetHolder.parent().css("opacity", 1);
        },
      });
    });
  }

  $("[id=view_grid]").each(function () {
    var pagination_action = $(this).find(
      '.pagination a.ab_numbers[data-page="1"]'
    );
    const asset_holder = $(this).find(".asset_holder");
    if (pagination_action.length > 0) {
      const filters = pagination_action.attr("data-post-filter");
      const post_type = get_filter(filters, "post_type");
      const page_key = get_page_key(post_type);

      const prev_page = cookies.get(page_key);

      if (
        prev_page !== undefined &&
        prev_page != "" &&
        prev_page != 1 &&
        window.performance &&
        window.performance.navigation.type ===
          window.performance.navigation.TYPE_BACK_FORWARD
      ) {
        asset_holder.parent().css("opacity", 0.6);
        pagination_action = $(this).find(
          '.pagination a.ab_numbers[data-page="' + prev_page + '"]'
        );
        get_content(pagination_action, asset_holder, prev_page);
      } else {
        const filters = pagination_action.attr("data-post-filter");
        const post_type = get_filter(filters, "post_type");

        const page_key = get_page_key(post_type);
        cookies.set(page_key, 1, 1);
      }
    }
  });

  $(document).on("click", ".pagination a.ab_numbers", function (e) {
    e.preventDefault();
    var paginationAction = $(this);

    if (paginationAction.hasClass("current") === false) {
      var assetHolder = paginationAction
        .parent()
        .parent()
        .children(".asset_holder");
      assetHolder.parent().css("opacity", 0.6);
      get_content(paginationAction, assetHolder);
    }
  });

  // Create Prospect Page modal
  $(document).on("click", ".add_prospect_page_btn", function () {
    jQuery(function ($) {
      $.colorbox({
        inline: true,
        href: "#add_prospect_page",
        onCleanup: function () {
          $("#modal_new_prospect_page #client_name").val("");
          $("#modal_new_prospect_page #client_company").val("");
          $("#modal_new_prospect_page #intro").val("");
        },
      });
    });
  });

  // Relate existing deals modal
  $(document).on("click", ".existing_deal_btn", function (e) {
    e.preventDefault();
    jQuery(function ($) {
      $.colorbox({
        inline: true,
        href: "#relate_deal",
        onCleanup: function () {},
      });
    });
  });

  // Create Prospect Page modal
  $(document).on("click", ".add_attachments_btn", function () {
    jQuery(function ($) {
      $.colorbox({
        inline: true,
        href: "#add_file_attachments",
        onCleanup: function () {
          $("#modal_new_attachment #file_name").val("");
        },
      });
    });
  });

  // Create prospect page action
  $(document).on("submit", "#modal_new_prospect_page", function (e) {
    e.preventDefault();
    $(this).find("#submit_prospect_page_btn").prop("disabled", true);

    //Get values from form
    var $inputs = $(this).find(":input");
    var values = {};
    values["add_resource"] = new Array();
    $inputs.each(function () {
      if ($(this).val() !== "") {
        if ($(this).attr("name") === "add_resource[]") {
          values["add_resource"].push($(this).val());
        } else {
          values[this.name] = $(this).val();
        }
      }
    });

    values["action"] = "ab_save_prospect_page";

    $.ajax({
      url: APPLICATION_URL,
      async: false,
      data: values,
      type: "POST",
      success: function (response) {
        result = JSON.parse(response);
        if (result) {
          window.location.href = "/prospect-pages/" + result.prospect_page;
        }
      },
      error: function (request, status, error) {
        console.log("returned error");
      },
    });
  });

  // Upload file attachment action
  $(document).on("submit", "#modal_new_attachment", function (e) {
    e.preventDefault();
    var submitBtn = $(this).find("#submit_attachement_btn");
    if (submitBtn.prop("disabled") === false) {
      submitBtn.prop("disabled", true);

      var fd = new FormData();
      var file = jQuery(document).find("input[name=file_attachment]");
      var file_name = jQuery(this).find("input[name=file_name]");
      var post_id = jQuery(this).find("input[name=file_post_id]");
      var user_id = jQuery(this).find("input[name=file_user_id]");

      var individual_file = file[0].files[0];

      fd.append("file_attachment", individual_file);
      fd.append("file_name", file_name.val());
      fd.append("post_id", post_id.val());
      fd.append("user_id", user_id.val());
      fd.append("action", "ab_add_file_sales_coach");

      var actualContent = $(".engagement_file_list");

      $.ajax({
        url: APPLICATION_URL,
        data: fd,
        type: "POST",
        success: function (response) {
          var content = JSON.parse(response);

          var contentHtml =
            '<div class="engagement_list"><a href="https://' +
            window.location.host +
            "/view-document?post_id=" +
            post_id.val() +
            "&file_id=" +
            content.f_index +
            '" target="_blank">' +
            '<span class="icon icon-attachment"></span>' +
            '<span class="engagement_title">' +
            content.content_name +
            "</span>" +
            '<span class="engagement_date">Uploaded ' +
            content.content_date +
            "</span>" +
            '<span class="icon-remove btn_delete sales_delete hasTip" title="Delete attachment"' +
            'data-post-id="' +
            content.resource_id +
            '"' +
            'data-file-index="' +
            content.f_index +
            '"' +
            'data-file-name="' +
            content.content_name +
            '"' +
            "></span></a></div>";
          actualContent.prepend(contentHtml);
          file.val("");
          submitBtn.prop("disabled", false);
          $("div#cboxClose").click();
          $("div.no-attachemts-message").remove();
        },
        error: function (request, status, error) {
          submitBtn.prop("disabled", false);
        },
        cache: false,
        contentType: false,
        processData: false,
      });
    }
  });

  // Relate existing deals action
  $(document).on("submit", "#modal_relate_deal", function (e) {
    e.preventDefault();
    $(this).find("#submit_relate_deal_btn").prop("disabled", true);

    var $inputs = $("#modal_relate_deal :input");
    var values = {};
    $inputs.each(function () {
      if ($(this).val() !== "") {
        values[this.name] = $(this).val();
      }
    });

    values["action"] = "ab_relate_deals";

    $.ajax({
      url: APPLICATION_URL,
      async: false,
      data: values,
      type: "POST",
      success: function (response) {
        result = JSON.parse(response);
        if (result.prospect_page) {
          location.reload();
        }
      },
      error: function (request, status, error) {
        console.log("returned error");
      },
    });
  });

  // Remove
  $(document).on("click", ".engagement_list a span.sales_delete", function (e) {
    e.preventDefault();
    var salesContent = {};

    salesContent["post_id"] = $(this).attr("data-post-id");
    salesContent["file_index"] = $(this).attr("data-file-index");
    salesContent["file_name"] = $(this).attr("data-file-name");
    salesContent["action"] = "ab_remove_file_sales_coach";

    var contentElement = $(this);

    if (window.confirm("Are you sure you want to delete this item?")) {
      $.ajax({
        url: APPLICATION_URL,
        async: false,
        data: salesContent,
        type: "POST",
        success: function (response) {
          result = JSON.parse(response);

          if (result.result) {
            contentElement.closest("div").remove();
          }
        },
        error: function (request, status, error) {
          console.log("returned error");
        },
      });
    }
  });

  // Remove
  $(document).on("click", ".title__logo span.logo_delete", function (e) {
    e.preventDefault();
    var companyLogo = {};

    companyLogo["id"] = $(this).attr("data-company-id");
    companyLogo["action"] = "ab_remove_company_logo";
    companyLogo["logo"] = "";

    var contentElement = $(this);

    if (window.confirm("Are you sure you want to delete this item?")) {
      $.ajax({
        url: APPLICATION_URL,
        async: false,
        data: companyLogo,
        type: "POST",
        success: function (response) {
          result = JSON.parse(response);

          if (result.result) {
            contentElement.closest("div").remove();
          }
        },
        error: function (request, status, error) {
          console.log("returned error");
        },
      });
    }
  });

  $('[data-js="icon-select"]').each(function (index) {
    $(this).on("change", function () {
      var value = this.value;
      $("#dash_icon_display_" + index).removeClass();
      $("#dash_icon_display_" + index).addClass(this.value);
    });
  });

  // Zendesk Create ticket trigger function for Profile Dropdown
  $(document).on("click", ".create_ticket_link", function (e) {
    e.preventDefault();
    zE("webWidget", "open");
    zE("webWidget", "show");
  });

  $(document).on("click", ".edit_options .options_button", function (e) {
    if ($(this).parent().hasClass("active")) {
      $(this).parent().removeClass("active");
      closeEditOptions();
    } else {
      window.addEventListener("click", handleProspectOptionsClick);
      $(this).parent().addClass("active");
    }
  });

  // if notification page then add a subtitle
  if ($(".page_header .wrapper h1").text() === "Notifications") {
    $(".page_header .wrapper h1").after("<h3>Last 30 days</h3>");
  }
}); // close jQuery declaration

// dynamically update mdf credit balance on mdf forms
jQuery(() => {
  const mdfBalance = jQuery(".mdf-credit-balance-amount");
  const submitter = jQuery(".owner_id_dropdown select");
  if (!mdfBalance.length || !submitter.length) {
    jQuery(".mdf-credit-balance").hide();
    return;
  }
  const updateAmount = () => {
    const user_id = submitter.val().trim();
    if (!user_id) {
      mdfBalance.text(0);
      return;
    }
    jQuery.ajax({
      url: ajaxurl,
      type: "GET",
      data: {
        user_id,
        action: "get_mdf_balance",
      },
      success: (result) => {
        mdfBalance.text(result.data.formatted_balance_amount ?? 0);
      },
    });
  };
  updateAmount();
  submitter.on("change", updateAmount);
});

var showAppDemoStorePopup = function (url) {
  // TODO :: Add exit modal on Escape
  // create transparent background
  var elBackground = document.createElement("div");
  elBackground.setAttribute(
    "style",
    "position: fixed; top:0; left:0; width:100%; height:100%; background-color:rgba(0,0,0,0.8); z-index:1000; cursor:pointer"
  );
  elBackground.onclick = function () {
    fClose();
  };
  document.body.appendChild(elBackground);

  // create iframe to load the demo page
  var elFrame = document.createElement("iframe");
  elFrame.src = url;
  // TODO :: Fix Styles to reduce the size of iframe clicking on elBackground would close the fullScreen Modal
  elFrame.setAttribute(
    "style",
    "position:relative; top:60px; left:5%; width:90%; height:90%; border:none;"
  );
  elBackground.appendChild(elFrame);

  // create element to close the popup
  var elClose = document.createElement("div");
  elClose.innerHTML = "x";
  elClose.setAttribute(
    "style",
    "position:absolute; top:60px; right:0; margin:10px; cursor:pointer; color:white; font-size: 150%;"
  );
  elBackground.appendChild(elClose);
  elClose.onclick = fClose;
  var fClose = function () {
    document.body.removeChild(elBackground);
  };
};

const getTrainingProgressStatus = async () => {
  return new Promise(function (resolve) {
    jQuery.ajax({
      url: ajaxurl,
      data: {
        action: "is_training_progress_enabled",
      },
      type: "GET",
      success: (response) => {
        const parsedResponse = JSON.parse(response);
        resolve(parsedResponse?.result == "1");
      },
      error: () => {
        resolve(false);
      },
    });
  });
};

let myPlayer,
  currentTimeInterval,
  progressInterval,
  learningTrackStatus,
  learningTrackData,
  videoProgressData,
  isSupportAsset;

//"https://www.youtube.com/watch?v=xjS6SftYQaQ"
//"https://cdn.allbound.com/sanity-ab/2023/01/04172603/big_buck_bunny_720p_5mb.mp4"
// "https://vimeo.com/347119375"
// "http://fast.wistia.com/embed/iframe/b0767e8ebb"
const contentViewClickHandler = async (
  url,
  id,
  resourceId,
  action,
  points,
  queryString = "",
  trackPoints = 0,
  isSupportingAsset = false
) => {
  isSupportAsset = isSupportingAsset;

  let learningTrackId;
  let trackQueryStr = queryString?.split("?")?.[1];
  let urlParams = new URLSearchParams(trackQueryStr ?? window.location.search);
  let isLearningTrack = urlParams.has("track");
  if (isLearningTrack) learningTrackId = urlParams.get("track");

  let trainingProgressEnabled = await getTrainingProgressStatus();

  let isYoutubeVideo = /(youtube\.com|youtu\.be)/.test(url);
  let isVimeoVideo = url?.includes("vimeo");
  let isWistiaVideo =
    /^.*(fast.wistia\.(com|net)\/)(embed\/iframe\/|medias\/)([A-z0-9]+)/.test(
      url
    );
  let isCustomWistiaVideo =
    /https?:\/\/(?!.*fast)([a-zA-Z0-9]+\.)?wistia\.(com|net)\/(embed\/iframe\/\w+|medias\/\w+)/.test(
      url
    );

  let newTabContent =
    url?.includes("vidyard") ||
    (!isYoutubeVideo &&
      !isCustomWistiaVideo &&
      !isVimeoVideo &&
      !isWistiaVideo &&
      !isUrlVideoType(url));

  if (trainingProgressEnabled && learningTrackId && !isSupportingAsset) {
    let res;
    try {
      res = await api.get(
        "progress",
        {
          filter: {
            "resource.id": learningTrackId,
            "user.id": window?.abUser?.id,
            showAll: true,
            status: "active,completed",
          },
        },
        trainingApiUrl,
        "application/json"
      );
    } catch (e) {
      console.log(
        "Error in calling GET training progress api",
        e?.responseJSON
      );
    }

    learningTrackData = res?.data?.data?.[0];

    learningTrackStatus = learningTrackData?.status;
    videoProgressData = learningTrackData?.meta?.find((el) => el.id == id);
  }

  if (isCustomWistiaVideo && !isSupportingAsset) {
    patchContentProgress(
      learningTrackId,
      null,
      0,
      0,
      id,
      "custom-wistia",
      trackPoints,
      trainingProgressEnabled
    );
    ab_log(action, resourceId, points);
    return;
  }

  if (newTabContent) {
    if (isSupportingAsset) {
      window.open(url, "_blank").focus();
      return;
    }
    patchContentProgress(
      learningTrackId,
      null,
      0,
      0,
      id,
      "new-tab-content",
      trackPoints,
      trainingProgressEnabled
    );
    ab_log(action, resourceId, points);
    window.open(url, "_blank").focus();
    return;
  }

  let elBackground = document.createElement("div");
  elBackground.setAttribute(
    "style",
    "position: fixed; top:0; left:0; width:100%; height:100%; background-color:rgba(0,0,0,0.8); z-index:1000; cursor:pointer"
  );
  document.body.appendChild(elBackground);

  // create element to close the popup
  let elClose = document.createElement("div");
  elClose.innerHTML = "x";
  elClose.setAttribute(
    "style",
    "position:absolute; top:60px; right:0; margin:10px; cursor:pointer; color:white; font-size: 150%; border: 1px solid white;border-radius: 50%;height: 30px;width: 30px;display: flex;align-items: center;justify-content: center;"
  );

  const fClose = function () {
    if (isVimeoVideo) {
      myPlayer.destroy();
    } else {
      myPlayer.dispose();
    }
    clearInterval(currentTimeInterval);
    clearInterval(progressInterval);
    document.body.removeChild(elBackground);
  };
  elClose.onclick = fClose;

  //Close video on Escape
  document.onkeydown = function (e) {
    if (e.key === "Escape" || e.key === "Esc") {
      fClose();
    }
  };

  if (!isVimeoVideo) {
    // Create Video Element
    const div = document.createElement("div");
    const videoContainer = document.createElement("div");
    const videoEl = document.createElement("video");
    videoEl.id = "video-" + id;
    videoEl.className = "video-js vjs-big-play-centered";
    videoEl.controls = true;
    videoContainer.setAttribute("style", "width: 66.6%; height: 75%");
    videoEl.style = "width: 100%; height: 100%";
    videoEl.preload = "auto";

    videoContainer.appendChild(videoEl);
    div.appendChild(videoContainer);
    div.setAttribute(
      "style",
      "position:relative; top:20%; left:25%; width:75%; height:80%; border:none;"
    );
    elBackground.appendChild(div);

    elBackground.appendChild(elClose);

    videoEl.dataset.setup = `{"techOrder": ["html5", "youtube", "wistia"],"sources": [{ "type": "video/${
      isYoutubeVideo ? "youtube" : isWistiaVideo ? "wistia" : "mp4"
    }", "src": "${url}"}]}`;

    launchVideoJsPlayer(
      id,
      action,
      resourceId,
      points,
      isWistiaVideo,
      videoProgressData,
      learningTrackId,
      trackPoints,
      trainingProgressEnabled
    );
  } else if (isVimeoVideo) {
    const newDiv = document.createElement("div");
    newDiv.id = "video-" + id;
    newDiv.setAttribute(
      "style",
      "position:relative; top:20%; left:25%; width:50%; height:40%; border:none;"
    );
    elBackground.appendChild(newDiv);

    elBackground.appendChild(elClose);
    newDiv.dataset.vimeoUrl = url;
    launchVimeoPlayer(
      id,
      action,
      resourceId,
      points,
      videoProgressData,
      learningTrackId,
      trackPoints,
      trainingProgressEnabled
    );
  }
};

function downloadFileFromJs(url, filename) {
  fetch(url, { mode: "cors" }) // Ensure CORS mode is enabled
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.blob();
    })
    .then((blob) => {
      const link = document.createElement("a");
      const blobUrl = URL.createObjectURL(blob);
      link.href = blobUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    })
    .catch((error) => {
      console.error("Failed to download file:", error);
    });
}

function getS3Url(apiEndpoint, requestData, callback) {
  var xhr = new XMLHttpRequest();

  xhr.open("POST", apiEndpoint, true);
  xhr.setRequestHeader("Authorization", "Bearer " + cookies.get("ab_token"));
  xhr.setRequestHeader("instance", instance);
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.setRequestHeader("Accept", "application/json");

  xhr.onload = function () {
    if (xhr.status >= 200 && xhr.status < 300) {
      var response = JSON.parse(xhr.responseText);
      if (response.data[0].image_url && requestData.data.file_name) {
        callback(response.data[0].image_url, requestData.data.file_name);
      } else {
        console.error("Invalid response format:", response);
      }
    } else {
      console.error("Failed to get S3 URL:", xhr.statusText);
    }
  };

  xhr.onerror = function () {
    console.error("Network error occurred while getting S3 URL.");
  };

  xhr.send(JSON.stringify(requestData));
}

function initiateFileDownload(url, filename) {
  const requestData = {
    data: {
      url,
      file_name: filename,
    },
  };
  const apiEndpoint = apiUrl + "content/download";
  getS3Url(apiEndpoint, requestData, function (s3Url, filename) {
    downloadFileFromJs(s3Url, filename);
  });
}

const launchVideoJsPlayer = (
  id,
  action,
  resourceId,
  points,
  isWistiaVideo = false,
  videoProgressData,
  learningTrackId,
  trackPoints,
  trainingProgressEnabled
) => {
  myPlayer =
    videojs.getPlayer(`video-${id}`) ||
    videojs(`video-${id}`, {
      playbackRates: [0.5, 1],
    });

  let currentTime =
    (videoProgressData?.progress !== 100
      ? videoProgressData?.videoTimestamp
      : 0) ||
    localStorage.getItem(`video-${id}-progress`) ||
    0;
  let isContentViewed = false;
  let videoDuration, videoCompletion;
  myPlayer.ready(function () {
    const clearTimer = () => {
      clearInterval(progressInterval);
      setVideoProgress();
      localStorage.removeItem(`video-${id}-progress`);
    };

    myPlayer.on("ended", clearTimer);

    const setVideoProgress = () => {
      currentTime = myPlayer.currentTime() ?? currentTime;
      videoCompletion = currentTime / videoDuration;
      if (videoCompletion > 0.9 && !isContentViewed) {
        ab_log(action, resourceId, points);
        isContentViewed = true;
      }
      if (learningTrackId) {
        patchContentProgress(
          learningTrackId,
          videoCompletion,
          videoDuration,
          currentTime,
          id,
          "video",
          trackPoints,
          trainingProgressEnabled
        );
      }
      localStorage.setItem(`video-${id}-progress`, currentTime);
    };

    const playfromCurrentTime = () => {
      if (!myPlayer.isDisposed()) {
        videoDuration = myPlayer.duration();
        myPlayer.currentTime(currentTime);
        myPlayer.play();
      }
    };
    if (isWistiaVideo) {
      playfromCurrentTime();
    }

    myPlayer.on("loadedmetadata", playfromCurrentTime);

    myPlayer.on("pause", function () {
      if (!isWistiaVideo) setVideoProgress();
      clearInterval(progressInterval);
    });

    myPlayer.on("seeked", function () {
      setVideoProgress();
    });

    const interval = trainingProgressEnabled ? 5000 : 1000;

    myPlayer.on("play", function () {
      if (progressInterval) clearInterval(progressInterval);
      progressInterval = setInterval(setVideoProgress, interval);
    });
  });
};

const launchVimeoPlayer = (
  id,
  action,
  resourceId,
  points,
  videoProgressData,
  learningTrackId,
  trackPoints,
  trainingProgressEnabled
) => {
  myPlayer = new Vimeo.Player(`video-${id}`, {
    responsive: true,
  });

  let currentTime =
    (videoProgressData?.progress !== 100
      ? videoProgressData?.videoTimestamp
      : 0) ||
    localStorage.getItem(`video-${id}-progress`) ||
    0;
  let isContentViewed = false;
  let videoDuration, videoCompletion;
  myPlayer.ready().then(function () {
    const playfromCurrentTime = async () => {
      videoDuration = await myPlayer.getDuration();
      myPlayer.setCurrentTime(currentTime);
    };

    myPlayer.on("loadedmetadata", playfromCurrentTime);

    const clearTimer = () => {
      clearInterval(progressInterval);
      setVideoProgress();
      localStorage.removeItem(`video-${id}-progress`);
    };

    myPlayer.on("ended", clearTimer);

    const setVideoProgress = async () => {
      currentTime = (await myPlayer.getCurrentTime()) ?? currentTime;
      videoCompletion = currentTime / videoDuration;
      if (videoCompletion >= 0.9 && !isContentViewed) {
        ab_log(action, resourceId, points);
        isContentViewed = true;
      }
      if (learningTrackId) {
        patchContentProgress(
          learningTrackId,
          videoCompletion,
          videoDuration,
          currentTime,
          id,
          "video",
          trackPoints,
          trainingProgressEnabled
        );
      }
      localStorage.setItem(`video-${id}-progress`, currentTime);
    };

    myPlayer.on("seeked", function () {
      setVideoProgress();
    });

    myPlayer.on("pause", function () {
      setVideoProgress();
      clearInterval(progressInterval);
    });

    const interval = trainingProgressEnabled ? 5000 : 1000;

    myPlayer.on("play", function () {
      if (progressInterval) clearInterval(progressInterval);
      progressInterval = setInterval(setVideoProgress, interval);
    });
  });
};

const isUrlVideoType = (url) => {
  const videoFormats = ["mp4", "mov", "webm", "avi", "wmv", "mpeg"];
  const newUrl = new URL(url);
  const splitUrl = newUrl.pathname.split(".");
  const extension = splitUrl?.[splitUrl.length - 1];
  if (videoFormats.includes(extension)) return true;
  return false;
};

const getContentStatus = async (contentId) => {
  return new Promise(function (resolve, reject) {
    jQuery.ajax({
      url: ajaxurl,
      data: {
        action: "validate_post_completion",
        resource_id: contentId,
      },
      type: "GET",
      success: (response) => {
        const parsedResponse = JSON.parse(response);
        const isQuizCompleted = parsedResponse?.result;
        const containsQuiz = parsedResponse?.contains_quiz;
        resolve({ isQuizCompleted, containsQuiz });
      },
      error: (error) => {
        reject(error);
      },
    });
  });
};

let apiCallInProgress = false;
let patchApiFailed = false;
let contentQuizInfo;
const patchContentProgress = async (
  learningTrackId,
  videoCompletion,
  videoDuration,
  currentTime,
  contentId,
  contentType = "video",
  trackGamificationPoints,
  trainingProgressEnabled
) => {
  if (
    !trainingProgressEnabled ||
    !learningTrackId ||
    learningTrackStatus !== "active" ||
    learningTrackData?.progress == 100 ||
    videoProgressData?.progress == 100 ||
    isSupportAsset
  )
    return;
  try {
    if (!apiCallInProgress && !patchApiFailed) {
      apiCallInProgress = true;
      if (!contentQuizInfo) contentQuizInfo = await getContentStatus(contentId);
      const { isQuizCompleted = false, containsQuiz = false } = contentQuizInfo;

      let progress;

      if (contentType === "video") {
        if (containsQuiz) {
          progress = isQuizCompleted
            ? 100
            : videoCompletion * 100 < 90
            ? Math.floor(videoCompletion * 100)
            : 99;
        } else {
          progress =
            videoCompletion * 100 < 90
              ? Math.floor(videoCompletion * 100)
              : 100;
        }
      } else {
        progress = containsQuiz ? (isQuizCompleted ? 100 : 0) : 100;
      }

      const data = {
        id: Number(contentId),
        progress,
        videoTimestamp:
          videoCompletion * 100 < 90 ? Math.floor(currentTime) : videoDuration,
      };

      if (contentType !== "video") delete data.videoTimestamp;
      const response = await api.patch(
        `progress/${learningTrackId}`,
        JSON.stringify(data),
        trainingApiUrl,
        "application/json"
      );
      if (
        learningTrackData?.progress != 100 &&
        response?.data?.progress == 100
      ) {
        jQuery.ajax({
          url: ajaxurl,
          data: {
            action: "log_training_event",
            resource_id: learningTrackId,
            gamification_points: trackGamificationPoints,
            track_progress: 100,
          },
          type: "GET",
        });
      }
      learningTrackData =
        response?.data?.resourceId == learningTrackId
          ? response?.data
          : learningTrackData;
      videoProgressData = learningTrackData?.meta?.find(
        (el) => el.id == contentId
      );
    }
  } catch (e) {
    patchApiFailed = true;
    console.log(
      `Error in updating video progress for instance ${instance} `,
      e?.responseJSON
    );
  } finally {
    apiCallInProgress = false;
  }
};

function throttle(func, delay) {
  let lastCalledTime = 0;

  return function () {
    const now = new Date().getTime();

    if (now - lastCalledTime >= delay) {
      func.apply(null, arguments);
      lastCalledTime = now;
    } else {
      window.alert(
        "Loading content, please wait for 5 seconds and click again."
      );
    }
  };
}

const handleContentViewClick = throttle(contentViewClickHandler, 5000);
