/*
 *	Javasciprt for Allbound Playbooks
 */

var APPLICATION_URL =
  "https://" + window.location.host + "/wp-admin/admin-ajax.php";

function ab_playbook(
  var_playbook_id,
  var_resource_id,
  var_action_performed,
  var_playbook_title,
  playbook_type
) {
  // get values or set defaults

  jQuery.ajax({
    url: APPLICATION_URL,
    async: false,
    data: {
      action: "ab_update_playbook",
      user_id: ab_user_id,
      playbook_id: var_playbook_id,
      resource_id: var_resource_id,
      playbook_title: var_playbook_title,
      playbook_type,
      action_performed: var_action_performed,
    },
    type: "POST",
    success: function (response) {
      //log successful
      result = jQuery.parseJSON(response);
      ab_log(var_action_performed, var_resource_id, 0);
    },
    error: function (request, status, error) {
      console.log("returned error");
      //showMessage('Sorry, there was an error connecting. Please try again later.','OK');
    },
  });
}

function ab_company_playbook(
  var_playbook_id,
  var_resource_id,
  var_action_performed,
  var_playbook_title
) {
  // get values or set defaults
  jQuery.ajax({
    url: APPLICATION_URL,
    async: false,
    data: {
      action: "ab_update_company_playbook",
      user_id: ab_user_id,
      partner_id: window.abUser.company_id,
      playbook_id: var_playbook_id,
      resource_id: var_resource_id,
      playbook_title: var_playbook_title,
      action_performed: var_action_performed,
    },
    type: "POST",
    success: function (response) {
      //log successful
      result = jQuery.parseJSON(response);
      ab_log(var_action_performed, var_resource_id, 0);
    },
    error: function (request, status, error) {
      console.log("returned error");
      //showMessage('Sorry, there was an error connecting. Please try again later.','OK');
    },
  });
}

jQuery(document).ready(function ($) {
  // $(".added_to_playbook").on("click", function (e) {
  //   e.preventDefault();
  //   var playbook_id = $(this).attr("href");
  //   ab_playbook(
  //     playbook_id,
  //     ab_page_resource_id,
  //     $(this).attr("data-log-action")
  //   );
  //   $(this).addClass("in_playbook");
  //   //setTimeout(function() {
  //   //$('.add_to_playbook ul').slideUp(300);
  //   //}, 1400);
  //   return false;
  // });

  $("#new_play_submit").on("submit", function (e) {
    e.preventDefault();
    var playbook_title = $("#new_play_submit #new_book").val();
    var playbook_type = $("#playbook_type").val();
    var user_playbook_id = $("#user_playbook_id").val();
    var company_playbook_id = $("#company_playbook_id").val();

    // Function to handle processing for userPlaybooks
    function handleUserPlaybooks(ids) {
      ids.forEach(function (id) {
        ab_playbook(id, ab_page_resource_id, $(this).attr("data-log-action"));
      });
    }

    // Function to handle processing for companyPlaybooks
    function handleCompanyPlaybooks(ids) {
      ids.forEach(function (id) {
        ab_company_playbook(
          id,
          ab_page_resource_id,
          $(this).attr("data-log-action"),
          playbook_title
        );
      });
    }

    if (playbook_type === "userPlaybooks") {
      if (Array.isArray(user_playbook_id) && user_playbook_id.length > 0) {
        handleUserPlaybooks(user_playbook_id);
        $(".existing_play_submit_conf").addClass("active");
      } else {
        ab_playbook(
          0,
          ab_page_resource_id,
          $(this).attr("data-log-action"),
          playbook_title
        );
        $(".new_play_submit_conf").addClass("active");
      }
    }
    if (playbook_type === "companyPlaybooks") {
      if (
        Array.isArray(company_playbook_id) &&
        company_playbook_id.length > 0
      ) {
        handleCompanyPlaybooks(company_playbook_id);
        $(".existing_play_submit_conf").addClass("active");
      } else {
        ab_company_playbook(
          0,
          ab_page_resource_id,
          $(this).attr("data-log-action"),
          playbook_title
        );
        $(".new_play_submit_conf").addClass("active");
      }
    }

    setTimeout(function () {
      //$('.add_to_playbook ul').slideUp(300);
      $(".new_play_submit_conf").removeClass("active");
      $(".existing_play_submit_conf").removeClass("active");
      //$('.new_playbook form').slideUp(300);
      //$('.create_playbook a').removeClass('active');
    }, 3000);
    return false;
  });

  // remove single asset from playbook
  $(".remove_resource").on("click", function (e) {
    e.preventDefault();
    var playbook_id = $(this).attr("data-playbook-id");
    var resource_id = $(this).attr("data-resource-id");
    ab_playbook(playbook_id, resource_id, $(this).attr("data-log-action"), "");
    $(this).closest("article").hide("slow");
    return false;
  });

  // delete entire user playbook
  $(".playbook_delete").on("click", function (e) {
    e.preventDefault();
    var playbook_id = $(this).attr("data-playbook-id");
    var playbook_type = $(this).attr("data-playbook-type");

    ab_playbook(
      playbook_id,
      "",
      $(this).attr("data-log-action"),
      "",
      playbook_type ?? ""
    );
    window.location.href = "/playbooks/";
    return false;
  });
});

function setActiveTab(tabName) {
  const buttons = document.querySelectorAll(".buttons_container button");
  buttons.forEach((button) => button.classList.remove("active"));

  const contentDivs = document.querySelectorAll(
    ".pinned_content .view_options"
  );
  contentDivs.forEach((div) => (div.style.display = "none"));

  const pinnedContentBtn = document.getElementById("pinnedBtn");
  const companyPlaybookBtn = document.getElementById("playbookBtn");

  const pinnedContentSection = document.getElementById("pinned_content");
  const companyPlaybooksSection = document.getElementById("company_playbook");

  if (tabName === "pinned_content") {
    if (pinnedContentBtn) {
      pinnedContentBtn.classList.add("active");
    }
    if (pinnedContentSection) {
      pinnedContentSection.style.display = "block";
    }
  } else if (tabName === "company_playbook") {
    if (companyPlaybookBtn) {
      companyPlaybookBtn.classList.add("active");
    }
    if (companyPlaybooksSection) {
      companyPlaybooksSection.style.display = "block";
    }
  }
}
