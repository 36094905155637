var APPLICATION_URL =
  "https://" + window.location.host + "/wp-admin/admin-ajax.php";

function getTransposhLanguageConfig(originalHref) {
  var langArr = [
    "en",
    "af",
    "sq",
    "am",
    "ar",
    "hy",
    "az",
    "eu",
    "ba",
    "be",
    "bn",
    "bs",
    "bg",
    "my",
    "ca",
    "yue",
    "ceb",
    "ny",
    "zh",
    "zh-tw",
    "co",
    "hr",
    "cs",
    "da",
    "nl",
    "eo",
    "et",
    "fj",
    "fil",
    "fi",
    "fr",
    "fy",
    "gl",
    "ka",
    "de",
    "el",
    "gu",
    "ht",
    "ha",
    "haw",
    "hmn",
    "mw",
    "he",
    "mrj",
    "hi",
    "hu",
    "is",
    "ig",
    "id",
    "ga",
    "it",
    "ja",
    "jw",
    "kn",
    "kk",
    "km",
    "ky",
    "ko",
    "ku",
    "lo",
    "la",
    "lv",
    "lt",
    "lb",
    "mk",
    "mg",
    "ms",
    "ml",
    "mt",
    "mi",
    "mr",
    "mhr",
    "mn",
    "ne",
    "no",
    "otq",
    "pap",
    "fa",
    "pl",
    "pt",
    "pa",
    "ro",
    "ru",
    "sm",
    "gd",
    "sr",
    "st",
    "sn",
    "sd",
    "si",
    "sk",
    "sl",
    "so",
    "es",
    "su",
    "sw",
    "sv",
    "tl",
    "ty",
    "tg",
    "ta",
    "tt",
    "te",
    "th",
    "to",
    "tr",
    "udm",
    "uk",
    "ur",
    "uz",
    "vi",
    "cy",
    "xh",
    "yi",
    "yo",
    "yua",
    "zu",
  ];
  var langVal, colabId, url, relativeUrl;
  var origin = window.location.origin;
  var pathname = window.location.pathname;
  var splittedUrl = pathname.split("/").filter((val) => val !== "");
  var langVal = splittedUrl[0];
  var hasLangURL = langArr.includes(langVal);
  if (hasLangURL) {
    colabId = splittedUrl[2];
    relativeUrl = "/" + langVal + originalHref;
  } else {
    colabId = splittedUrl[1];
    langVal = "";
    relativeUrl = originalHref;
  }
  if (originalHref) {
    url = origin + relativeUrl;
  }
  return {
    colabId,
    hasLangURL,
    languageValue: langVal,
    newURL: url,
    relativeURL: relativeUrl,
  };
}

// On load actions
jQuery(function ($) {
  // Auto-resize textarea on colabs. Just give the textarea an attr of data-autoresize
  $.each($("textarea[data-autoresize]"), function () {
    var offset = this.offsetHeight - this.clientHeight;

    var resizeTextarea = function (el) {
      $(el)
        .css("height", "auto")
        .css("height", el.scrollHeight + offset);
    };
    $(this)
      .on("keyup input", function () {
        resizeTextarea(this);
      })
      .removeAttr("data-autoresize");
  });

  // scroll to the bottom of the message list div
  $.each($(".message_holder"), function () {
    this.scrollTop = this.scrollHeight;
  });

  // Create colab action
  $(document).on("submit", "#form_colab", function (e) {
    e.preventDefault();
    $(this).find("#submit_colab_btn").prop("disabled", true);

    //Get values from form
    var $inputs = $(this).find(":input");
    var values = {};
    $inputs.each(function () {
      if ($(this).val() !== "") {
        values[this.name] = $(this).val();
      }
    });

    if (values.id === undefined) {
      createColab(values);
    } else {
      updateColab(values);
    }
  });

  // Open colab update modal
  $(document).on("click", "#update_colab_modal", function () {
    // Get colab_id
    //CHECKING FOR LANGUAGE PARAMETER TO GET CORRECT INDEX OF COLAB_ID.
    var config = getTransposhLanguageConfig();
    var colab_id = config.colabId;
    // Get colab details
    $.ajax({
      url: APPLICATION_URL + "?id=" + colab_id,
      data: { action: "ab_get_colabs" },
      type: "GET",
      success: function (response) {
        // Bring up colab modal to edit details
        openColabModal(response);
      },
    });
  });

  // Adds comment to a Co/Lab and loads comments with ajax
  $(document).on("submit", ".colab_add_comment", function (e) {
    e.preventDefault();

    var commentForm = $(this);

    var values = {
      user_id: commentForm.children("#user_id").val(),
      colab_id: commentForm.children("#colab_id").val(),
      comment: commentForm.children("#comment").val(),
    };

    // Adds Co/Lab comment via Ajax
    createColabComment(values);

    // resets the height of the textarea if it was resized from the previous comment
    $(this).find("#comment").css("height", "auto").addClass("test");
  });

  // Uploads shared content to a Co/Lab
  $(document).on("submit", ".colab_add_content", function (e) {
    e.preventDefault();
    var submitBtn = $(this).find("input[type=submit]");
    if (submitBtn.hasClass("disabled") === false) {
      submitBtn.disabled = true;
      submitBtn.addClass("disabled");
      var fd = new FormData();
      var file = jQuery(document).find("input[name=file_colab_content]");
      var colab_id = jQuery(this).find("input[name=file_colab_id]");
      var user_id = jQuery(this).find("input[name=file_user_id]");
      var individual_file = file[0].files[0];

      fd.append("userfile", individual_file);
      fd.append("colab_id", colab_id.val());
      fd.append("user_id", user_id.val());
      fd.append("action", "ab_add_colab_content");

      var actualContent = $(".shared_content_list");

      $.ajax({
        url: APPLICATION_URL,
        data: fd,
        type: "POST",
        success: function (response) {
          var content = JSON.parse(response);
          var contentHtml = "";

          if (content.user_name && content.content_id) {
            contentHtml = `<div class='shared_content'>
                            <a href='https://${
                              window.location.host
                            }/view-document?post_id=${colab_id.val()}&file_id=${
              content.content_id
            }' target='_blank'>
                              ${content.content_name}
                            </a>
                            ${content.delete_option}
                            <div class='colab_content_details'>
                              ${content.user_name} - ${content.content_date}
                            </div>
                          </div>`;

            actualContent.prepend(contentHtml);

            createColabComment({
              user_id: user_id.val(),
              colab_id: colab_id.val(),
              comment: `Shared Content: 
                      <a href='https://${
                        window.location.host
                      }/view-document?post_id=${colab_id.val()}&file_id=${
                content.content_id
              }' target='_blank'>
                        ${content.content_name}
                      </a>`,
              disable_notification: true,
            });
          }
          file.val("");
        },
        cache: false,
        contentType: false,
        processData: false,
      });
      submitBtn.disabled = false;
      submitBtn.removeClass("disabled");
    }
  });

  // Deletes Co/Lab comment
  $(document).on("click", ".message_item .colab_delete", function (e) {
    var values = {};
    values.comment_id = $(this).attr("data-comment-id");
    values.colab_id = $(this).attr("data-colab-id");
    var colab_delete = $(this);

    var actualMessage = $(this).parent().parent().children(".message_content");

    if (values.colab_id && values.comment_id) {
      if (confirm("Would you like to delete this comment?")) {
        $.ajax({
          url: APPLICATION_URL,
          data: {
            action: "ab_delete_colab_comment",
            input: values,
          },
          type: "POST",
          success: function (response) {
            //actualMessage.html("<i>Message deleted ...</i>");
            colab_delete.closest(".message_item").slideUp();
          },
        });
      }
    }
  });

  // Deletes shared content
  $(document).on(
    "click",
    ".shared_content_list .shared_content .colab_delete",
    function (e) {
      var currentContent = $(this);
      var values = {
        content_id: currentContent.attr("data-content-id"),
        colab_id: currentContent.attr("data-colab-id"),
      };

      if (values.colab_id && values.content_id) {
        $.ajax({
          url: APPLICATION_URL,
          data: {
            action: "ab_delete_colab_content",
            input: values,
          },
          type: "POST",
          success: function (response) {
            var response = JSON.parse(response);

            // verify that item was deleted
            if (response.result.data == true) {
              currentContent.closest("div").remove();
            }
          },
        });
      }
    }
  );

  // delete colab attachment
  $(document).on(
    "click",
    ".colab_attachment_list .shared_content .colab_delete",
    function (e) {
      var values = {
        post_id: $(this).attr("data-post-id"),
        colab_id: $(this).attr("data-colab-id"),
        user_id: $(this).attr("data-user-id"),
      };

      var currentContent = $(this);

      if (values.colab_id && values.post_id && values.user_id) {
        $.ajax({
          url: APPLICATION_URL,
          data: {
            action: "ab_delete_colab_attachment",
            input: values,
          },
          type: "POST",
          success: function (response) {
            currentContent.closest("div").remove();
          },
        });
      }
    }
  );

  // Invites user to a Co/Lab
  $(document).on("submit", "#form_colab_members", function (e) {
    e.preventDefault();
    $("#submit_colab_member_btn").prop("disabled", true);

    //Get values from form
    var $inputs = $("#form_colab_members :input");
    var values = {};
    $inputs.each(function () {
      if ($(this).val() !== "") {
        values[this.name] = $(this).val();
      }
    });

    if (values.invite != null) {
      $.ajax({
        url: APPLICATION_URL,
        data: {
          action: "ab_add_colab_user",
          input: { user_id: values.invite, colab_id: values.id },
        },
        type: "POST",
        success: function (response) {
          var response = JSON.parse(response);

          if (response.result.status_code === 200) {
            window.location.href = "/colabs/" + response.slug;
          }
        },
      });
    }
  });

  // Deletes Co/Lab
  $(document).on("click", ".colabs_table span.icon-remove", function (e) {
    e.preventDefault();
    var values = {};
    values.colab_id = $(this).attr("data-colab-id");

    if (values.colab_id) {
      var currentColab = $(this);
      $.ajax({
        url: APPLICATION_URL,
        data: {
          action: "ab_delete_colab",
          input: values,
        },
        type: "POST",
        success: function (response) {
          var result = JSON.parse(response);
          if (result.colab_id) {
            currentColab.closest("tr").remove();
          }
        },
      });
    }
  });

  // Allows users to leave a Co/Lab
  $(document).on("click", ".leaveColab", function (e) {
    e.preventDefault();
    var values = {};
    values.colab_id = $(this).data("colab-id");
    values.user_id = $(this).data("user-id");

    if (values.colab_id != undefined && values.colab_id != "") {
      $.ajax({
        url: APPLICATION_URL,
        data: {
          action: "ab_delete_user_colab",
          input: { colab_id: values.colab_id, user_id: values.user_id },
        },
        type: "POST",
        success: function (response) {
          var result = JSON.parse(response);

          if (result.status_code === 200) {
            window.location.href = "/";
          } else {
            alert(result.message);
          }
        },
      });
    }
  });

  // Attach Co/Lab content action
  $(document).on("submit", "form[name='attach_colab_content']", function (e) {
    e.preventDefault();
    var submitBtn = $(this).find("input[type=submit]");

    var $inputs = $('form[name="attach_colab_content"] :input');
    var values = {};
    var post_name = $("#attach_content option:selected").html();

    if (submitBtn.hasClass("disabled") === false) {
      submitBtn.disabled = true;
      submitBtn.addClass("disabled");

      $inputs.each(function () {
        if ($(this).val() !== "" && $(this).attr("name") !== undefined) {
          values[this.name] = $(this).val();
        }
      });

      // Ajax call to create relationship between coalb and post
      $.ajax({
        url: APPLICATION_URL,
        data: {
          action: "ab_attach_post",
          input: values,
        },
        type: "POST",
        success: function (response, status, xhr) {
          var response = JSON.parse(response);

          var attach_response = response.result.data;

          createColabComment({
            user_id: values.user_id,
            colab_id: values.colab_id,
            comment:
              'Co/Lab Content "<a href="' +
              attach_response.post_permalink +
              '">' +
              post_name +
              '</a>" has been attached.',
            disable_notification: true,
          });

          $(".colab_attachment_list").html(attach_response.content_html);

          $("#attach_content").select2({
            placeholder: "Select...",
            allowClear: true,
          });
          $("#attach_content").select2("val", "");
        },
      });
      submitBtn.disabled = false;
      submitBtn.removeClass("disabled");
    }
  });
  // Apply select2 style on load
  $(
    "select#colab_invite_select, select#colab_invite, select#attach_content"
  ).select2({
    minimumResultsForSearch: 10,
    placeholder: "Select...",
    allowClear: true,
  });

  // Set invite_colab_member as  modal pupup
  $("a.invite_member_btn").colorbox({
    inline: true,
    maxWidth: "620px",
    opacity: 0.9,
    onLoad: function () {
      $("#colab_invite").select2("val", "");
    },
  });

  // Create Co/Lab modal
  $(document).on("click", ".add_colab_btn", function () {
    jQuery(function ($) {
      var values = {
        user_id: $("#admin_id").val(),
        post_id: $(".add_colab_btn").attr("data-resource-id"),
      };

      $("#colab_invite_select [value='" + values.user_id + "']").attr(
        "selected",
        true
      );

      $.colorbox({
        inline: true,
        href: "#add_colab",
        onComplete: function () {
          // Add a deal_id input to realte it with the colab
          if (!$("#form_colab input#post_id").length) {
            $(
              "<input name='post_id' type='hidden' value='" +
                values.post_id +
                "'>"
            ).appendTo("#form_colab");
          }
        },
        onCleanup: function () {
          // Remove input after is closed
          if ($("#form_colab input#post_id").length) {
            $("#form_colab input#post_id").remove();
          }
        },
      });
    });
  });
});

// Updates colab and user(s)
function updateColab(values) {
  jQuery(function ($) {
    $.ajax({
      url: APPLICATION_URL,
      data: {
        action: "ab_update_colab",
        input: values,
      },
      type: "POST",
      success: function (response) {
        var update_colab_response = JSON.parse(response);
        var result = update_colab_response.result;

        if (result.status_code === 200) {
          window.location.href = "/colabs/" + update_colab_response.slug;
        }
      },
    });
  });
}

// Creates a new colab with user(s)
function createColab(values) {
  jQuery(function ($) {
    $.ajax({
      url: APPLICATION_URL,
      data: {
        action: "ab_create_colab",
        input: values,
      },
      type: "POST",
      success: function (response) {
        var response = JSON.parse(response);
        var result = response.result;

        if (values.post_id != null) {
          location.reload();
        } else {
          var redirectionURL = "/colabs/" + response.slug;
          var config = getTransposhLanguageConfig(redirectionURL);
          window.location.href = config.newURL;
        }
      },
    });
  });
}

// Opens colab modal form to update or create a new colab
function openColabModal(args) {
  jQuery(function ($) {
    $("#colab_invite_select [value='1']").attr("selected", true);

    $.colorbox({
      inline: true,
      href: "#add_colab",
      onComplete: function () {
        if (args !== undefined) {
          var colab = JSON.parse(args);

          $("#add_colab #modal_title, #submit_colab_btn").text("UPDATE CO/LAB");

          // set default values to update
          $("#form_colab #colab_id").val(colab.id);
          $("#form_colab #colab_name").val(colab.name);
          $("#form_colab #colab_description").val(colab.description);

          // set select2 component default values workaround
          $("#form_colab .select2-selection__rendered").html("");
          $("#colab_invite_select option:selected").removeAttr("selected");

          $.each(colab.invite, function (i, e) {
            var option = $("#colab_invite_select option[value='" + e + "']");
            // atach tag if it is not empty @todo fix this workaround to pass inn ids to select2 web component
            if (option.text() != "") {
              option.attr("selected", option.text() != "" ? true : false);
              $("#form_colab .select2-selection__rendered").append(
                '<li class="select2-selection__choice" title="' +
                  option.html() +
                  '"><span class="select2-selection__choice__remove" role="presentation">×</span>' +
                  option.html() +
                  "</li>"
              );
            }
          });

          // // Set select2 to filter users on input
          $("#colab_invite_select").select2({
            minimumResultsForSearch: 10,
            placeholder: "Select...",
            allowClear: true,
          });
        }
      },
      onCleanup: function () {
        //Reset colab form values
        $("#add_colab #modal_title, #submit_colab_btn").text("CREATE CO/LAB");
        $(":input", "#form_colab")
          .not(":button, :submit, :reset")
          .val("")
          .removeAttr("checked")
          .removeAttr("selected");

        $("#form_colab #colab_invite_select").removeAttr("selected");

        $("#form_colab .select2-selection__rendered").html(
          '<li class="select2-search select2-search--inline"><input class="select2-search__field" type="search" tabindex="0" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" role="textbox" placeholder="Select..." style="width: 100px;"></li>'
        );
      },
    });
  });
}

function isValidUrl(urlString) {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
}

function structureMessage(message) {
  try {
    var aLink = message.split("&gt;");
    var hrefItem = aLink.shift();
    aLink = aLink.join("&gt;");
    var linkName = aLink.split("&lt;/a&gt;");
    var aLinkUrl = hrefItem.split("&lt;a href=");
    var preName = aLinkUrl[0];
    preName = typeof preName === "string" ? preName.replace(/"/g, "") : "";
    var postName = linkName[linkName.length - 1];
    postName = typeof postName === "string" ? postName.replace(/"/g, "") : "";
    var validUrl = aLinkUrl[1].substring(1, aLinkUrl[1].length - 1);

    return !!isValidUrl(validUrl)
      ? "<span>" +
          "<span>" +
          preName +
          "</span>" +
          "<a href=" +
          "'" +
          validUrl +
          "'" +
          ">" +
          linkName[0] +
          "</a>" +
          "<span>" +
          postName +
          "</span>" +
          "</span>"
      : message;
  } catch (err) {
    return message;
  }
}

// Injects new colab messages
function setColabMessages(messages) {
  jQuery(document).ready(function ($) {
    var messageContent = "";
    var commentDate = "";
    var actualConversation = $(".message_list");

    messages.forEach(function (item) {
      if (item.user_name && item.comment_content) {
        if (commentDate != item.comment_date) {
          commentDate = item.comment_date;
          messageContent +=
            "<div class='message_date'>" + item.comment_date + "</div>";
        }

        messageContent +=
          "<div class='message_item'>" +
          "<div class='message_pic'>" +
          item.avatar +
          "</div>" +
          "<div class='message_details'>" +
          "<div class='message_sender'>" +
          item.user_name +
          "<span class='date_sent'> " +
          item.comment_hour +
          "</span>" +
          "</div>" +
          "<div class='message_content'>" +
          "<pre>" +
          structureMessage(item.comment_content) +
          "</pre>" +
          "</div>" +
          "</div>" +
          item.delete_option +
          "</div>";
      }
    });

    actualConversation.html(messageContent);
    $(".message_holder").animate(
      { scrollTop: actualConversation.height() },
      "slow"
    );
    activateTipTip();
  });
}

// Gets colab pageload messages
function getColabMessages() {
  jQuery(document).ready(function ($) {
    var colab_id = $(".colab_add_comment").children("#colab_id").val();

    if (colab_id) {
      $.ajax({
        url: APPLICATION_URL + "?colab_id=" + colab_id,
        data: {
          action: "ab_get_colab_comment_format",
        },
        type: "GET",
        success: function (response) {
          var messages = JSON.parse(response);
          setColabMessages(messages);
        },
      });
    }
  });
}

// Calls tipTip every time this is called
function activateTipTip() {
  jQuery(".hasTip").tipTip({ maxWidth: "auto", edgeOffset: 10 });
}

// Creates colab comment
function createColabComment(values) {
  if (values.user_id && values.colab_id && values.comment) {
    jQuery.ajax({
      url: APPLICATION_URL,
      data: {
        action: "ab_add_colab_comment",
        input: values,
      },
      type: "POST",
      success: function (response) {
        setColabMessages(JSON.parse(response));
        jQuery(".colab_add_comment").children("#comment").val("");
      },
    });
  }
}
