/*
 *	Javasciprt for Allbound Log
 */

var APPLICATION_URL =
  "http://" + window.location.host + "/wp-admin/admin-ajax.php";

/**
 * ab_pin
 * @param var_user_id
 * @param var_resource_id
 * @param var_action_preformed
 */
function ab_pin(
  var_user_id,
  var_resource_id,
  var_action_preformed,
  isCompanyPlaybookSection
) {
  // get values or set defaults
  //var_user_id = typeof var_user_id !== 'undefined' ? var_user_id : 0;
  //var_resource_id = typeof var_resource_id !== 'undefined' ? var_resource_id : 0;
  //var_action_preformed = typeof var_action_preformed !== 'undefined' ? var_action_preformed : 'add';

  jQuery.ajax({
    url: APPLICATION_URL,
    async: false,
    data: {
      action: "ab_pin",
      user_id: var_user_id,
      resource_id: var_resource_id,
      action_performed: var_action_preformed,
      reset_company_playbook_cache: isCompanyPlaybookSection,
    },
    type: "POST",
    success: function (response) {
      result = response;
    },
    error: function (request, status, error) {
      console.log("returned error");
      //showMessage('Sorry, there was an error connecting. Please try again later.','OK');
    },
  });
}

/**
 * Action events on document scope for pin on dashboard
 */
jQuery(document).ready(function ($) {
  // call the pin content function //_pinContent();
  $(document).on("click", "span.pins", function () {
    action = $(this).attr("data-log-action");
    var count = $(".counter_pins").html();
    if (action == "added_to_pins") {
      // increase the counter by 1
      $(".counter_pins").html(++count);
    } else if (action == "removed_from_pins") {
      // decrease the counter by 1
      $(".counter_pins").html(--count);
    }

    var isCompanyPlaybookSection;

    if ($(this).closest(".pinned_content_with_playbook")?.length > 0) {
      isCompanyPlaybookSection = true;
    }

    ab_pin(
      ab_user_id,
      $(this).attr("data-log-resource-id"),
      $(this).attr("data-log-action"),
      isCompanyPlaybookSection
    );

    // toggle the tooltip title
    /*
      $(this).attr("title") always returns undefined
    */
    // $(this).attr(
    //   "title",
    //   $(this).attr("title") == "Pin to dashboard"
    //     ? "Unpin from dashboard"
    //     : "Pin to dashboard"
    // );

    // toggle the action
    $(this).attr(
      "data-log-action",
      jQuery(this).attr("data-log-action") == "added_to_pins"
        ? "removed_from_pins"
        : "added_to_pins"
    );

    $(this).toggleClass("pinned");
    const isCompanyPlaybook = $(this).closest("#company_playbook").length > 0;
    if (!isCompanyPlaybook) {
      $(this).closest(".asset").toggleClass("removed_from_pins");
    }

    $(this).tipTip({
      defaultPosition: "top",
      maxWidth: "300px",
      delay: 100,
      fadeIn: 200,
      fadeOut: 400,
      content:
        action == "added_to_pins" ? "Unpin from dashboard" : "Pin to dashboard",
    });

    return false;
  });

  $(document).on("click", "span.likes", function () {
    action = jQuery(this).attr("data-log-action");
    var count = jQuery(".counter_likes").html();
    if (action == "added_to_likes") {
      // increase the counter by 1
      jQuery(".counter_likes").html(++count);
    } else if (action == "removed_from_likes") {
      // decrease the counter by 1
      jQuery(".counter_likes").html(--count);
    }

    ab_like(
      ab_user_id,
      jQuery(this).attr("data-log-resource-id"),
      jQuery(this).attr("data-log-action")
    );

    // toggle the tooltip title
    /*
      jQuery(this).attr("title") this values is always undefined
    */
    // jQuery(this).attr(
    //   "title",
    //   jQuery(this).attr("title") == "Like" ? "Unlike" : "Like"
    // );

    // toggle the action
    jQuery(this).attr(
      "data-log-action",
      jQuery(this).attr("data-log-action") == "added_to_likes"
        ? "removed_from_likes"
        : "added_to_likes"
    );

    jQuery(this).toggleClass("liked");

    jQuery(this).tipTip({
      defaultPosition: "top",
      maxWidth: "300px",
      delay: 100,
      fadeIn: 200,
      fadeOut: 400,
      content: action == "added_to_likes" ? "Unlike" : "Like",
    });

    return false;
  });
});
