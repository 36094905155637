const cookies = {
  get: function (key) {
    const value = "; " + document.cookie;
    const sep = "; " + key + "=";
    const parts = value.split(sep);
    if (parts.length === 2) {
      return parts.pop().split(";").shift();
    }
  },

  set: function (key, value, days_expiration) {
    var expires = "";
    if (days_expiration) {
      var date = new Date();
      date.setTime(date.getTime() + days_expiration * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = key + "=" + (value || "") + expires + "; path=/";
  },
};

const api = {
  call: function (method, endpoint, data, serviceUrl, contentType) {
    if (data === undefined) {
      data = {};
    }

    const api_token = cookies.get("ab_token");
    const api_instance = instance;
    const api_url = serviceUrl ?? apiUrl;

    const apiConfig = {
      type: method,
      url: api_url + endpoint,
      data: data,
      dataType: "json",
      headers: {
        Authorization: "Bearer " + api_token,
        instance: api_instance,
      },
      statusCode: {
        401: function () {
          if (idpId) {
            window.location =
              "https://" + window.location.host + "/?idp=" + idpId;
          } else if (jwtIdpId) {
            window.location =
              "https://" + window.location.host + "/?jwt_idp=" + jwtIdpId;
          } else {
            jQuery.ajax({
              url:
                "https://" + window.location.host + "/wp-admin/admin-ajax.php",
              data: {
                action: "ab_force_logout",
              },
              type: "POST",
              success: function (response) {
                console.log("Logged out due to missing ab_token");
                window.location = "/";
              },
              error: function (request, status, error) {
                console.log("returned error during force logout");
                window.location = "/";
              },
            });
          }
        },
      },
    };

    if (contentType) {
      apiConfig.contentType = contentType;
    }

    const thing = jQuery.ajax(apiConfig);

    return thing;
  },

  get: function (endpoint, data, serviceUrl, contentType) {
    return this.call("GET", endpoint, data, serviceUrl, contentType);
  },

  delete: function (endpoint) {
    return this.call("DELETE", endpoint, {});
  },

  post: function (endpoint, data) {
    return this.call("POST", endpoint, data);
  },

  put: function (endpoint, data, serviceUrl, contentType) {
    return this.call("PUT", endpoint, data, serviceUrl, contentType);
  },

  patch: function (endpoint, data, serviceUrl, contentType) {
    return this.call("PATCH", endpoint, data, serviceUrl, contentType);
  },

  file: function (endpoint, data, method) {
    if (method === undefined) {
      method = "POST";
    }

    // use this transport for "binary" data type
    jQuery.ajaxTransport("+binary", function (options, originalOptions, jqXHR) {
      // check for conditions and support for blob / arraybuffer response type
      if (
        window.FormData &&
        ((options.dataType && options.dataType == "binary") ||
          (options.data &&
            ((window.ArrayBuffer && options.data instanceof ArrayBuffer) ||
              (window.Blob && options.data instanceof Blob))))
      ) {
        return {
          // create new XMLHttpRequest
          send: function (headers, callback) {
            // setup all variables
            var xhr = new XMLHttpRequest(),
              url = options.url,
              type = options.type,
              async = options.async || true,
              // blob or arraybuffer. Default is blob
              dataType = options.responseType || "blob",
              data = options.data || null,
              username = options.username || null,
              password = options.password || null;

            xhr.addEventListener("load", function () {
              var data = {};
              data[options.dataType] = xhr.response;
              // make callback and send data
              callback(
                xhr.status,
                xhr.statusText,
                data,
                xhr.getAllResponseHeaders()
              );
            });

            xhr.open(type, url, async, username, password);

            // setup custom headers
            for (var i in headers) {
              xhr.setRequestHeader(i, headers[i]);
            }

            xhr.responseType = dataType;
            xhr.send(data);
          },
          abort: function () {
            jqXHR.abort();
          },
        };
      }
    });

    if (data === undefined) {
      data = {};
    }

    const api_token = cookies.get("ab_token");
    const api_instance = cookies.get("ab_instance");
    const api_url = decodeURIComponent(cookies.get("ab_api_url"));

    const thing = jQuery.ajax({
      type: method,
      url: api_url + endpoint,
      data: data,
      dataType: "binary",
      headers: {
        Authorization: "Bearer " + api_token,
        instance: api_instance,
      },
      error: function (r) {
        console.error(r);
      },
      cache: false,
      contentType: false,
      processData: false,
    });

    return thing;
  },

  validateToken: function () {
    return this.get("validateToken");
  },
};
