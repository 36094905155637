/*
 *	Javasciprt for Allbound Log
 */

var APPLICATION_URL =
  "https://" + window.location.host + "/wp-admin/admin-ajax.php";

function ab_log(var_action_performed, var_resource_id, var_points) {
  var_action_performed =
    typeof var_action_performed !== "undefined" ? var_action_performed : 0;
  var_resource_id =
    typeof var_resource_id !== "undefined"
      ? var_resource_id
      : ab_page_resource_id;
  var_points = typeof var_points !== "undefined" ? var_points : 0;

  // get values or set defaults
  jQuery.ajax({
    url: APPLICATION_URL,
    async: true,
    data: {
      action: "ab_log",
      user_id: ab_user_id,
      resource_id: var_resource_id,
      action_performed: var_action_performed,
      points: var_points,
      url: window.location.href,
    },
    type: "POST",
    success: function (response) {
      //log successful
      result = jQuery.parseJSON(response);
      ret = 0;
      if (var_points) {
        ret = var_points;
      }
      if (
        typeof result === "object" &&
        typeof result.points !== "undefined" &&
        result.points > 0
      ) {
        // Display on screen popup notification.
        var action = result.action
          .replace(/_/g, " ")
          .replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          });
        popup_nofitication(
          "You earned " + result.points + " points",
          "<b>Action</b>: " + action,
          "icon-points"
        );
      }
      return ret;
      //console.log(result.result + " | " + result.user_id + " | " + result.resource_id + " | " + result.action_performed);
    },
    error: function (request, status, error) {
      // console.log("returned false");
      // showMessage('Sorry, there was an error connecting. Please try again later.','OK');
    },
  });
  return true;
}

function ab_log_as_read(post_id, callback, async) {
  if (typeof async == "undefined") {
    async = true;
  }
  jQuery.ajax({
    url: "https://" + window.location.host + "/wp-admin/admin-ajax.php",
    async: async,
    data: {
      action: "ab_notification_read",
      user_id: ab_user_id,
      post_id: post_id,
    },
    type: "POST",
    success: function (response) {
      result = jQuery.parseJSON(response);
      callback(true);
    },
    error: function (request, status, error) {
      callback(false);
    },
  });
}

function ab_batch_notification_read(notifications, successCallback) {
  jQuery.ajax({
    url: APPLICATION_URL,
    type: "POST",
    data: {
      action: "ab_batch_notification_read",
      user_id: ab_user_id,
      notifications: notifications,
    },
    success: function (response) {
      result = jQuery.parseJSON(response);
      successCallback(result);
    },
  });
}

function init_ab_log() {
  jQuery(document).ready(function ($) {
    $(".log").on("click", function (e) {
      var action = $(this).closest("*").attr("data-log-action");
      var resource_id = $(this).closest("*").attr("data-log-resource-id");
      var points = $(this).closest("*").attr("data-log-points");

      $(this).closest("*").attr("data-log-points", "");
      points_earned = ab_log(action, resource_id, points);

      return true;
    });
  });
}

jQuery(document).ready(function ($) {
  if (
    typeof ab_user_id !== "undefined" &&
    typeof ab_page_resource_id !== "undefined"
  ) {
    const pathname = window.location.pathname.replace(/\//g, "");
    if (
      [
        "learn",
        "learning_track",
        "engage",
        "playbooks",
        "win",
        "incentive",
      ].indexOf(pathname) === -1
    ) {
      ab_log(ab_page_action, ab_page_resource_id, 0);
    }
    init_ab_log();
    // Logging site search results
    $("#searchsubmit").on("click", function () {
      var search_term_text = $("#s").val();
      //ab_log("Searched_Site", search_term_text);
      return true;
    });
    // new link added
    $("#link_new").on("click", function () {
      var url = $("#new_link_url").val();
      //ab_log("Added_Link", url);
      return true;
    });
  }
});
