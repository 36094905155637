!(function (t, i) {
  "function" == typeof define && define.amd
    ? define(["video.js"], function (e) {
        return (t.WistiaTech = i(e));
      })
    : "object" == typeof module && module.exports
    ? (module.exports = t.WistiaTech = i(require("video.js")))
    : (t.WistiaTech = i(t.videojs));
})(this, function (t) {
  "use strict";
  var i = { UNSTARTED: -1, ENDED: 0, PLAYING: 1, PAUSED: 2, BUFFERING: 3 },
    e = t.getComponent("Tech"),
    s = t.extend(e, {
      constructor: function (t, i) {
        e.call(this, t, i), this.setSrc(this.options_.source.src, !0);
      },
      dispose: function () {
        this.wistiaVideo.remove(), this.el_.parentNode.removeChild(this.el_);
      },
      createEl: function () {
        var i =
          "file:" === document.location.protocol
            ? "http:"
            : document.location.protocol;
        (this.wistia = {}),
          (this.wistiaInfo = {}),
          (this.baseUrl = i + "//fast.wistia.com/embed/iframe/"),
          (this.videoOptions = s.parseUrl(this.options_.source.src)),
          (this.videoId = this.videoOptions.videoId),
          (this.playedId = this.options_.playerId);
        var e = t.createEl("div", {
          id: this.videoId,
          className: this.videoOptions.classString,
          width: this.options_.width || "100%",
          height: this.options_.height || "100%",
        });
        this.wistiaScriptElement = t.createEl("script", {
          src: i + "//fast.wistia.com/assets/external/E-v1.js",
        });
        var o = document.createElement("div");
        o.setAttribute("id", "wistia-wrapper"), o.appendChild(e);
        var r = document.createElement("div");
        return (
          r.setAttribute("class", "vjs-iframe-blocker"),
          r.setAttribute(
            "style",
            "position:absolute;top:0;left:0;width:100%;height:100%"
          ),
          o.appendChild(r),
          e.insertBefore(this.wistiaScriptElement, e.firstChild),
          this.initPlayer(),
          o
        );
      },
      initPlayer: function () {
        var t = this;
        s.parseUrl(this.options_.source.src).videoId,
          (t.wistiaInfo = {
            state: i.UNSTARTED,
            volume: 1,
            muted: !1,
            muteVolume: 1,
            time: 0,
            duration: 0,
            buffered: 0,
            url: t.baseUrl + t.videoId,
            error: null,
          }),
          (this.wistiaScriptElement.onload = function () {
            (t.wistiaVideo = Wistia.api(t.videoId)),
              (window._wq = window._wq || []);
            var i = {};
            (i[t.videoId] = function (i) {
              (t.wistiaVideo = i), t.onLoad();
            }),
              window._wq.push(i);
          });
      },
      ended: function () {
        return this.wistiaInfo.state === i.ENDED;
      },
      onLoad: function () {
        this.wistiaInfo = {
          state: i.UNSTARTED,
          volume: 1,
          muted: !1,
          muteVolume: 1,
          time: 0,
          duration: this.wistiaVideo.duration(),
          buffered: 0,
          url: this.baseUrl + this.videoId,
          error: null,
        };
        var e = this;
        this.wistiaVideo.hasData(function () {
          e.onReady();
        }),
          this.wistiaVideo.embedded(
            function () {
              var i = t.getPlayers();
              if (i) {
                var e = i[this.playedId];
                if (e && e.controls()) {
                  var s = this.player_.el_.getElementsByTagName("video");
                  s.length && (s[0].style.pointerEvents = "none");
                }
              }
            }.bind(this)
          ),
          this.wistiaVideo.bind("pause", function () {
            e.onPause();
          }),
          this.wistiaVideo.bind("play", function () {
            e.onPlay();
          }),
          this.wistiaVideo.bind("seek", function (t, i) {
            e.onSeek({ seconds: t });
          }),
          this.wistiaVideo.bind("secondchange", function (t) {
            (e.wistiaInfo.time = t), e.player_.trigger("timeupdate");
          }),
          this.wistiaVideo.bind("end", function (t) {
            e.onFinish();
          });
      },
      onReady: function () {
        (this.isReady_ = !0),
          this.triggerReady(),
          this.trigger("loadedmetadata"),
          this.startMuted && (this.setMuted(!0), (this.startMuted = !1)),
          (this.wistiaInfo.duration = this.wistiaVideo.duration());
      },
      onLoadProgress: function (t) {
        var i = !this.wistiaInfo.duration;
        (this.wistiaInfo.duration = t.duration),
          (this.wistiaInfo.buffered = t.percent),
          this.trigger("progress"),
          i && this.trigger("durationchange");
      },
      onPlayProgress: function (t) {
        (this.wistiaInfo.time = t.seconds),
          this.wistiaVideo.time(this.wistiaInfo.time),
          this.trigger("timeupdate");
      },
      onPlay: function () {
        (this.wistiaInfo.state = i.PLAYING), this.trigger("play");
      },
      onPause: function () {
        (this.wistiaInfo.state = i.PAUSED), this.trigger("pause");
      },
      onFinish: function () {
        (this.wistiaInfo.state = i.ENDED), this.trigger("ended");
      },
      onSeek: function (t) {
        this.trigger("seeking"),
          (this.wistiaInfo.time = t.seconds),
          this.wistiaVideo.time(this.wistiaInfo.time),
          this.trigger("timeupdate"),
          this.trigger("seeked");
      },
      onError: function (t) {
        (this.error = t), this.trigger("error");
      },
      error: function () {
        switch (this.errorNumber) {
          case 2:
          case 100:
            return { code: "Unable to find the video" };
          case 5:
            return { code: "Error while trying to play the video" };
          case 101:
          case 150:
            return {
              code: "Playback on other Websites has been disabled by the video owner.",
            };
        }
        return { code: "Wistia unknown error (" + this.errorNumber + ")" };
      },
      playbackRate: function () {
        return this.suggestedRate ? this.suggestedRate : 1;
      },
      setPlaybackRate: function (t) {
        this.wistiaVideo &&
          (this.wistiaVideo.playbackRate(t),
          (this.suggestedRate = t),
          this.trigger("ratechange"));
      },
      src: function (t) {
        return t && this.setSrc({ src: t }), this.source;
      },
      setSrc: function (t) {
        t &&
          t.src &&
          ((this.source = t),
          (this.videoOptions = s.parseUrl(t.src)),
          this.wistiaVideo.replaceWith(
            this.videoOptions.videoId,
            this.videoOptions.options
          ));
      },
      supportsFullScreen: function () {
        return !0;
      },
      load: function () {},
      play: function () {
        this.wistiaVideo.play();
      },
      pause: function () {
        this.wistiaVideo.pause();
      },
      paused: function () {
        return (
          this.wistiaInfo.state !== i.PLAYING &&
          this.wistiaInfo.state !== i.BUFFERING
        );
      },
      currentTime: function () {
        return this.wistiaInfo.time || 0;
      },
      setCurrentTime: function (t) {
        this.wistiaVideo.time(t), this.player_.trigger("timeupdate");
      },
      duration: function () {
        return this.wistiaInfo.duration || 0;
      },
      buffered: function () {
        return t.createTimeRange(
          0,
          this.wistiaInfo.buffered * this.wistiaInfo.duration || 0
        );
      },
      volume: function () {
        return this.wistiaInfo.muted
          ? this.wistiaInfo.muteVolume
          : this.wistiaInfo.volume;
      },
      setVolume: function (t) {
        (this.wistiaInfo.volume = t),
          this.wistiaVideo.volume(t),
          this.player_.trigger("volumechange");
      },
      currentSrc: function () {
        return this.el_.src;
      },
      muted: function () {
        return this.wistiaInfo.muted || !1;
      },
      setMuted: function (t) {
        t
          ? ((this.wistiaInfo.muteVolume = this.wistiaInfo.volume),
            this.setVolume(0))
          : this.setVolume(this.wistiaInfo.muteVolume),
          (this.wistiaInfo.muted = t),
          this.player_.trigger("volumechange");
      },
    });
  (s.isSupported = function () {
    return !0;
  }),
    (s.canPlaySource = function (t) {
      return "video/wistia" === t.type;
    }),
    (s.parseUrl = function (t) {
      var i = { videoId: null, classes: [], classString: "", options: {} },
        e = {},
        s = (s =
          /^.*(wistia\.(com|net)\/)(embed\/iframe\/|medias\/)([A-z0-9]+)/),
        o = t.match(s);
      o && (i.videoId = o[4]);
      var r = [];
      r.push("vjs-tech"),
        r.push("wistia_embed"),
        r.push("wistia_async_" + i.videoId);
      var a = {};
      if (((a.wmode = "transparent"), t)) {
        var n = t.match(/playerColor=([#a-fA-f0-9]+)/);
        n && (e.playerColor = n[1]);
        var u = t.match(/playbar=(true|false)/);
        u && (e.playbar = u[1]);
        var l = t.match(/playButton=(true|false)/);
        l && (e.playButton = l[1]);
        var d = t.match(/smallPlayButton=(true|false)/);
        d && (e.smallPlayButton = d[1]);
        var h = t.match(/volumeControl=(true|false)/);
        h && (e.volumeControl = h[1]);
        var c = t.match(/fullscreenButton=(true|false)/);
        c && (e.fullscreenButton = c[1]);
        var f = t.match(/controlsVisibleOnLoad=(true|false)/);
        f && (e.controls = f[1]);
        var m = t.match(/chromeless=(true|false)/);
        m && (e.chromeless = m[1]);
        var p = t.match(/autoplay=(true|false)/);
        p && (e.autoplay = p[1]),
          t.match(/muted=(true|false)/) && (e.muted = !0);
        var v = t.match(/volume=([0-9]+)/);
        v && (e.volume = v[1]);
        var w = t.match(/endVideoBehavior=(loop|default|reset)/);
        w && (e.endVideoBehavior = w[1]);
      }
      var g = e.playerColor;
      g && "#" === g.substring(0, 1) && (e.playerColor = g.substring(1)),
        e.chromeless && (a.chromeless = e.chromeless),
        e.playbar && (a.playbar = e.playbar),
        e.playButton && (a.playButton = e.playButton),
        e.smallPlayButton && (a.smallPlayButton = e.smallPlayButton),
        e.volumeControl && (a.volumeControl = e.volumeControl),
        e.fullscreenButton && (a.fullscreenButton = e.fullscreenButton),
        e.controls && (a.controlsVisibleOnLoad = e.controls),
        e.playerColor && (a.playerColor = e.playerColor),
        e.autoplay && (a.autoPlay = e.autoplay),
        !1 !== e.volume && (a.volume = e.volume || 1),
        e.muted && (a.volume = 0),
        e.loop && (e.endVideoBehavior = "loop"),
        e.endVideoBehavior && (a.endVideoBehavior = e.endVideoBehavior);
      for (
        var y = Object.keys(a), I = r.join(" ") + " ", b = 0;
        b < y.length;
        b++
      ) {
        var $ = y[b],
          V = a[$];
        I += $ + "=" + V + "&";
      }
      return (
        (I = I.replace(/&+$/, "")),
        (i.classes = r),
        (i.classString = I),
        (i.options = a),
        i
      );
    }),
    t.registerTech("Wistia", s);
});
