(function ($) {
  // Allbound DataTable component with most common settings
  $.fn.AbDataTable = function (options) {
    // Default settings
    var settings = $.extend(
      {
        paging: true,
        ordering: true,
        stateSave: true,
        stateDuration: 0, // 0 = localstorage  -1 = sessionstorage
        dom: "Bfrtipl",
        pagingType: "simple_numbers",
        order: [],
        buttons: [
          {
            extend: "excel",
            text: "Excel",
            className: "buttons-excel",
            exportOptions: {
              columns: "th:not(.no-export)",
            },
          },
          {
            extend: "csv",
            text: "CSV",
            className: "buttons-csv",
            exportOptions: {
              columns: "th:not(.no-export)",
            },
          },
        ],
        aLengthMenu: [
          [10, 25, 50, 100, 250, -1],
          [10, 25, 50, 100, 250, "All"],
        ],
        oLanguage: {
          sSearch: "",
          sLengthMenu: "Show _MENU_",
          oPaginate: {
            sFirst: "&laquo;",
            sPrevious: "&lsaquo;",
            sLast: "&raquo;",
            sNext: "&rsaquo;",
          },
        },
        responsive: {
          breakpoints: [
            { name: "desktop", width: Infinity },
            { name: "tablet", width: 1024 },
            { name: "fablet", width: 768 },
            { name: "phone", width: 500 },
          ],
        },
        stateSaveParams: function (oSettings, oData) {
          oData.start = 0; // Start on page 1
          oData.search.search = ""; // Clean search input
        },
        fnStateSave: function (oSettings, oData) {
          localStorage.setItem(
            "DataTables_" + window.location.pathname,
            JSON.stringify(oData)
          );
        },
        fnStateLoad: function (oSettings) {
          return JSON.parse(
            localStorage.getItem("DataTables_" + window.location.pathname)
          );
        },
      },
      options
    );

    if (settings.persistent) {
      settings.stateSaveParams = function (oSetting, oData) {
        oData.search.search = ""; // Clean search input
      };
    }

    return this.each(function () {
      $(this).DataTable(settings);
    });
  };
})(jQuery);
